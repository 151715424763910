import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/footer";
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import MetaTags from 'react-meta-tags';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet-async";
import AnimatedNumbers from "react-animated-numbers";
import new_landing_intro from "../../assets/new_landing_intro.png";

import Stats from "../../assets/stats.png";
import dishaApp from "../../assets/disha.png";
import Vision_3D from "../../assets/3d-view.png";
import Electronics from "../../assets/electronics.jpg";
import Poster from "../../assets/poster.png";
import "./disha.css"


const steps = [
  {
    heading: "Frontend Layer",
    description: 
      "Intelligent Mobile App for field agents, brand partners, or sales associates to capture and upload geo-tagged live shelf images with least effort, time, and cost.",
  },
  {
    heading: "Tech Platform - AmIWare",
    description:
      "Context and task-ware AI platform leveraging 3D computer vision for giving accurate image analytics and insights from the shelf-image data.",
  },
  {
    heading: "HCI Dashboard",
    description:
      "Integrated HCI dashboard for increasing visibility of On-Shelf Availability, Out Of Stock items, and replenishment rate in Warehouses and at the Stores Front.",
  },
];

const tabStyle = {
  row: {
    marginLeft: 0,
    marginRight: 0,
  },
  leftCol: {
    paddingLeft: 0,
    paddingRight: 5,
  },
  rightCol: {
    paddingLeft: 5,
    paddingRight: 0,
  },
};

let config = [{"mass":1,"tension":30,"friction":10},{"mass":2,"tension":40,"friction":10},{"mass":3,"tension":30,"friction":10}]

export default function DishaScreen() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
      <Helmet>
        <title>Neophyte - Disha</title>
        <meta name="description" content="State-of-the-art Vision AI App powered by Augmented Reality and Ambient Intelligence"></meta>
        <meta name="keywords" content="NeoDishaRevolution, ShelfMonitoring, SupplyChainAutomation, RetailOptimization, RealTimeInsights, InventoryOptimization, BrandCompliance, AdvancedImageAnalysis, RetailTechnology, SupplyChainEfficiency, FutureOfRetail"></meta>
        <link rel="canonical" href="https://neophyte.ai/disha"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" ></meta>
      </Helmet>
      <div>
        <Modal
          size="xl"
          show={modalIsOpen}
          onHide={toggleModal}
          centered
          keyboard={false}
        >
          <ModalBody>
            <div className="forPC">
              <iframe
                style={{
                  width: "100%",
                  height: "80vh",
                }}
                src="https://www.youtube.com/embed/-VBnY7Oazes?si=PMxcHXmBnXw6WXa4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="forMobile">
              <iframe
                style={{
                  width: "100%",
                  height: "75vh",
                }}
                src="https://www.youtube.com/embed/-VBnY7Oazes?si=PMxcHXmBnXw6WXa4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </ModalBody>
        </Modal>
        <div className="sc-hm-one-sentinel">
          <div className="sc-hm-one-in-about">
            <>
              <Row>
                <Col md={6} xs={12} sm={12}>
                  <div className="text-pop-neometry">
                    <Fade bottom duration={1250}>
                      <p className="txt_ Title" id="myElement4">
                        Disha – Digital Shelf Analysis
                      </p>
                      <p className="txt_ subTitle">
                        State-of-the-art Vision AI App powered by Augmented Reality and Ambient Intelligence
                      </p>
                      <div className="forPC">
                        <div className="flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-4">
                          <button className="font-bold px-4 lg:px-10 font-[poppins] rounded-[60px] bg-cyan-500 text-gray-100 hocus:bg-cyan-700 focus:shadow-outline focus:outline-none transition duration-300">
                            <a href="#about-disha">Know More</a>
                          </button>
                          <button
                            onClick={toggleModal}
                            className="flex place-items-center bg-[transparent]"
                          >
                            <span className="sm:mt-0 flex items-center text-gray-300 transition duration-300 hocus:text-[#742cff] focus:outline-none">
                              <PlayIcon className="stroke-1 w-12 h-12" />
                            </span>
                            <span className="ml-2 font-[poppins] font-medium text-gray-300 self-center">
                              Watch Video
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="forMobile">
                        <Row style={tabStyle.row}>
                          <Col sm={6} xs={6} style={tabStyle.leftCol}>
                            <button className="mt-3 font-bold px-4 lg:px-10 font-[poppins] rounded-[60px] bg-cyan-500 text-gray-100 hocus:bg-cyan-700 focus:shadow-outline focus:outline-none transition duration-300">
                              <a href="#about-disha">Know More</a>
                            </button>
                          </Col>
                          <Col sm={6} xs={6} style={tabStyle.rightCol}>
                            <button
                              onClick={toggleModal}
                              className="flex place-items-center bg-[transparent]"
                            >
                              <span className="sm:mt-0 flex items-center text-gray-300 transition duration-300 hocus:text-[#742cff] focus:outline-none">
                                <PlayIcon className="stroke-1 w-12 h-12" />
                              </span>
                              <span className="ml-2 font-[poppins] font-medium text-gray-300 self-center">
                                Watch Video
                              </span>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Fade>
                  </div>
                </Col>
                <Col md={6} xs={12} sm={12}>
                  <>
                    <div className="video-pop-neometry forPC">
                      <ReactPlayer
                        url="https://www.youtube.com/embed/-VBnY7Oazes?si=PMxcHXmBnXw6WXa4"
                        playing={true}
                        loop={true}
                        muted={true}
                        width="100%"
                        height="370px"
                      />
                    </div>
                    <div className="video-pop-neometry forMobile">
                      <ReactPlayer
                        url="https://www.youtube.com/embed/-VBnY7Oazes?si=PMxcHXmBnXw6WXa4"
                        playing={true}
                        loop={true}
                        muted={true}
                        style={{ marginTop: "1%" }}
                        width="100%"
                        height="200px"
                      />
                    </div>
                  </>
                </Col>
              </Row>
            </>
          </div>
        </div>
      </div>
      <div className="content-box div2" id="about-neometry">
        <Row>
          <Col md={7} sm={12} xs={12}>
            <Fade bottom duration={1400}>
              <br className="forPC"></br><br className="forPC"></br>
              <p className="about-neometry-div2-title disha-header">Disha - Digital Shelf Analysis</p>
              <p className="about-midSide-div2-para">Enhances Sales by monitoring and encouraging <span className="amiware-hightlight">Shelf-Fullness</span> as per <span className="amiware-hightlight">Visual Merchandising</span> norms of "Availability, Assortment and Adjacency".</p>
              <p className="about-midSide-div2-para">Supports new brand Launches and promotions inside the retail store.</p>
              <p className="about-midSide-div2-para">Encourages availability of products by real-time Visual Intelligence triggering <span className="amiware-hightlight">Replenishment</span>.</p>
              <p className="about-midSide-div2-para">Integrates with Order Management System in a plug-and-play framework.</p>
              <p className="about-midSide-div2-para">Increases the effectiveness of <span className="amiware-hightlight">Cycle Counting, Audit, Defect Detection, Expiry Management and Inventory Management</span> in store / warehouse by reducing time and cost.</p>
            </Fade>
          </Col>
          <Col md={5} sm={12} xs={12}>
            <img src={new_landing_intro} className="stock_img_disha-final-card2"/>
          </Col>
        </Row>
      </div>
      <div className="sc-hm-one-disha" id="about-disha">
        <center>
          <div style={{marginBottom: "1.8%"}}>
            <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl">
              Key {" "}
              <span className="text-cyan-500 inline-block">
              Features
              </span>
            </h1>
          </div>
        </center>
        {/*<p className="product-subTitle font-normal">Disha enhances User experience with some crucial technology and features.</p>*/}
        <center>
          <div className="carousel-div-disha">
            <Row>
              <Col md={4} sm={12} xs={12}>
                <div className="disha_cards_keyFeatures">
                  <img src={dishaApp} className="disha_cards_keyFeatures_img"/>
                  <p className="disha_cards_keyFeatures_title">Intelligent Mobile App</p>
                  <p style={{color:"#222"}}>For field agents to capture and upload shelf images in a few clicks</p>
                </div>
              </Col>
              <Col md={4} sm={12} xs={12}>
                <div className="disha_cards_keyFeatures">
                    <img src={Vision_3D} className="disha_cards_keyFeatures_img"/>
                    <p className="disha_cards_keyFeatures_title">AI and 3D Vision</p>
                    <p style={{color:"#222"}}>Digital Twins technology for creating 3D shelf designs | Context-aware AI models for high accuracy</p>
                </div>
              </Col>
              <Col md={4} sm={12} xs={12}>
                <div className="disha_cards_keyFeatures">
                  <img src={Stats} className="disha_cards_keyFeatures_img"/>
                  <p className="disha_cards_keyFeatures_title">Decision Support</p>
                  <p style={{color:"#222"}}>Dashboard for live shelf status and analytics with a daily statistics <br className="forPC"/>report</p>
                </div>
              </Col>
            </Row>
          </div>
        </center>
      </div>
      <div className="our-solutions-disha">
        <Row>
          <Col md={6} xs={12} sm={12}>
            <img className="our-solutions-disha-img mt-6" src={Poster} alt="poster" />
          </Col>
          <Col md={6} xs={12} sm={12}>
            <h2 className="mt-4 font-black flex justify-center md:justify-start ml-0 md:ml-[30px] font-[poppins] text-white text-2xl sm:text-3xl lg:text-4xl leading-tight">
              Our <span className="text-cyan-500 px-3">Solutioning</span>{" "}
              Approach
            </h2>
            <div className="forPC">
              <ul className="mt-12">
                {steps.map((step, index) => (
                  <li
                    className="mt-4 flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:items-start"
                    key={index}
                  >
                    <div className="font-semibold font-[poppins] text-gray-400 text-4xl leading-none">
                      {(index + 1).toString().padStart(2, "0")}
                    </div>
                    <div className="md:ml-4 text-white">
                      <h6 className="leading-none font-[poppins] text-xl font-semibold">
                        {step.heading}
                      </h6>
                      <p className="mt-0 max-w-sm font-[poppins] text-sm text-gray-400 font-medium">
                        {step.description}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="forMobile" style={{paddingRight:"7%"}}>
              <>
                <ul className="mt-12">
                  {steps.map((step, index) => (
                    <li
                      className="mt-4 flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:items-start"
                      key={index}
                    >
                      <center>
                        <div className="font-semibold font-[poppins] text-gray-400 text-4xl leading-none">
                          {(index + 1).toString().padStart(2, "0")}
                        </div>
                      </center>
                      
                      <div className="md:ml-6 text-white">
                        <center>
                          <h6 className="leading-none font-[poppins] text-xl font-semibold">
                            {step.heading}
                          </h6>
                        </center>
                        <p style={{textAlign:"center"}} className="mt-0 max-w-xs font-[poppins] text-sm text-gray-400 font-medium">
                          {step.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            </div>
          </Col>
        </Row>
      </div>
      <div className="sc-hm-one-disha">
        <center>
          <div style={{marginBottom: "1.8%", marginTop: "7%"}}>
            <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl">
             Business{" "}
              <span className="text-cyan-500 inline-block">
              Impact
              </span>
            </h1>
          </div>
        </center>
        <>
          <div className="carousel-div-disha">
            <Row>
              <Col md={3} sm={12} xs={12}>
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent:"center", alignSelf:"center"}}>
                    <AnimatedNumbers
                      animateToNumber={10}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_dashed"> - </span>
                    <AnimatedNumbers
                      animateToNumber={15}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_percentage">%</span>
                  </div>
                  <center>
                    <p className="animatedNumberTitles">Improvement in<br/>Shelf-Fullness</p>
                    <p className="animatedNumberSubTitle">99% accurate SKU detection & classification</p>
                  </center>
                </>
              </Col>
              <Col md={3} sm={12} xs={12}>
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent:"center", alignSelf:"center"}}>
                    <AnimatedNumbers
                      animateToNumber={5}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_dashed"> - </span>
                    <AnimatedNumbers
                      animateToNumber={10}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_percentage">%</span>
                  </div>
                  <center>
                    <p className="animatedNumberTitles">Lift in Sales<br/>Per Store</p>
                    <p className="animatedNumberSubTitle">Optimal actuation for Retail Shrinkage</p>
                  </center>
                </>
              </Col>
              <Col md={3} sm={12} xs={12}>
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent:"center", alignSelf:"center"}}>
                    <AnimatedNumbers
                      animateToNumber={50}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_dashed"> - </span>
                    <AnimatedNumbers
                      animateToNumber={60}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_percentage">%</span>
                  </div>
                  <center>
                    <p className="animatedNumberTitles">Field force time saved<br/>Per Store</p>
                    <p className="animatedNumberSubTitle">Value and time saved<br className="forPC"/>per store</p>
                  </center>
                </>
              </Col>
              <Col md={3} sm={12} xs={12}>
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent:"center", alignSelf:"center"}}>
                    <AnimatedNumbers
                      animateToNumber={10}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_dashed"> - </span>
                    <AnimatedNumbers
                      animateToNumber={15}
                      fontStyle={{ fontSize: 55, fontWeight: 500, color:"#3b3b3b", letterSpacing: -1}}
                      configs={config}
                    />
                    <span className="animatedNumber_percentage">%</span>
                  </div>
                  <center>
                    <p className="animatedNumberTitles">Improvement in<br/>Inventory</p>
                    <p className="animatedNumberSubTitle">Context-aware AI for lower false alarms</p>
                  </center>
                </>
              </Col>
            </Row>
          </div>
        </>
      </div>
      <div className="sc-hm-one-disha-verticals">
        <center>
          <div style={{marginBottom: "1.8%"}}>
            <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl">
             Verticals &{" "}
              <span className="text-cyan-500 inline-block">
              Formats
              </span>
            </h1>
          </div>
        </center>
        <p className="product-subTitle font-normal">Disha transforms Beauty, Fashion, and Electronics retail experiences with tailored solutions for each sector.</p>
        <>
          <div className="carousel-div-disha">
            <Row>
              <Col md={4} sm={12} xs={12}>
                <article class="disha_verticals_card">
                  <img
                    class="disha_verticals_card__background"
                    src="https://media.fashionnetwork.com/m/f687/6e5a/3268/1c83/4528/a910/93ec/d7de/18b2/8774/8774.jpeg"
                    alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                    width="1920"
                    height="2193"
                  />
                  <div class="disha_verticals_card__content | flow">
                    <div class="disha_verticals_card__content--container | flow">
                      <h2 class="disha_verticals_card__title">Beauty & Cosmetics</h2>
                      <p class="disha_verticals_card__description">
                        <ul className="vision-bullets">
                          <li><span className="pricing-span">Shelf-fullness monitoring,</span></li>
                          <li><span className="pricing-span">Out of stock items alert, Planogram compliance</span></li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
              </Col>
              <Col md={4} sm={12} xs={12}>
                <article class="disha_verticals_card">
                  <img
                    class="disha_verticals_card__background"
                    src="https://im.whatshot.in/img/2021/Feb/reliance-trends-1612294286.jpg"
                    alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                    width="1920"
                    height="2193"
                  />
                  <div class="disha_verticals_card__content | flow">
                    <div class="disha_verticals_card__content--container | flow">
                      <h2 class="disha_verticals_card__title">Fashion & Lifestyle</h2>
                      <p class="disha_verticals_card__description">
                        <ul className="vision-bullets">
                          <li><span className="pricing-span">Authentic sourcing, Visual merchandising,</span></li>
                          <li><span className="pricing-span">On-shelf availability, Price and promotion compliances</span></li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
              </Col>
              <Col md={4} sm={12} xs={12}>
                <article class="disha_verticals_card">
                  <img
                    class="disha_verticals_card__background"
                    src={Electronics}
                    alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                    width="1920"
                    height="2193"
                  />
                  <div class="disha_verticals_card__content | flow">
                    <div class="disha_verticals_card__content--container | flow">
                      <h2 class="disha_verticals_card__title">Digital & Electronics</h2>
                      <p class="disha_verticals_card__description">
                        <ul className="vision-bullets">
                          <li><span className="pricing-span">Syndicated audits and cycle counts,</span></li>
                          <li><span className="pricing-span">Inventory record accuracy,</span></li>
                          <li> <span className="pricing-span">Accurate image analytics (Barcode/QR code/Texts)</span></li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
              </Col>
            </Row>
          </div>
        </>
      </div>
      <Footer />
    </div>
  );
}
