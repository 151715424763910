import React from "react";
import { withRouter } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-multi-carousel';
import Fade from 'react-reveal/Fade';
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { Helmet } from "react-helmet-async";

//Files
import search from "../../assets/search.png";
import summary from "../../assets/sumary.png";
import security from "../../assets/security.png";
import statistics from "../../assets/statistics.png";
import senti_card_1_webp from "../../assets/webimage/senti_card_1.webp";
import senti_card_2_webp from "../../assets/webimage/senti_card_9.webp";
import senti_card_3_webp from "../../assets/webimage/senti_card_3.webp";
import senti_card_5_webp from "../../assets/webimage/senti_card_5.webp";
import senti_card_6_webp from "../../assets/webimage/senti_card_6.webp";
import senti_card_7_webp from "../../assets/webimage/senti_card_7.webp";
import senti_card_8_webp from "../../assets/webimage/senti_card_8.webp";
import "./sentinel.css";
import "./sentinel.scss";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};

const tabStyle = {
  row: {
      marginLeft: 0,
      marginRight: 0
  },
  leftCol: {
      paddingLeft: 0,
      paddingRight: 5
  },
  rightCol: {
    paddingLeft: 5,
    paddingRight: 0
  }
};

class sentinel_screen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: false,
      leaders: true,
      switchView: true,
      videoModal: false
    };
    this.changeView = this.changeView.bind(this);
  }
  changeView() {
    this.setState({ switchView: !this.state.switchView });
  }
  componentDidMount() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
      <div className="container-box">
        <Helmet>
          <title>Neophyte - Sentinel</title>
          <meta name="description" content="Enhancing Business by Marketing In the Moment leveraging visual intelligence obtained by studying customer behaviour and interactions in the store"></meta>
          <meta name="keywords" content="TruckLoading, LogisticsOptimization, Sentinel3D, WarehouseManagement, SupplyChain, Profitability, CarbonFootprint, FleetManagement, Retail Store Monitoring, Marketing in the Moment, Visual Intelligence"></meta>
          <link rel="canonical" href="https://neophyte.ai/sentinel"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
        </Helmet>
        <Modal
          show={this.state.videoModal}
          onHide={() => this.setState({videoModal: false})}
          keyboard={false}
          centered
          size="xl"
        >
          <ModalBody>
            <div className="forPC">
              <iframe
                style={{
                  width: "100%",
                  height: "80vh",
                }}
                src="https://www.youtube.com/embed/QH7teksT_mQ?si=P2jjBqTwDrpVygXu"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="forMobile">
              <iframe
                style={{
                  width: "100%",
                  height: "75vh",
                }}
                src="https://www.youtube.com/embed/QH7teksT_mQ?si=P2jjBqTwDrpVygXu"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </ModalBody>
        </Modal>
        <div>
          <div>
            <div className="sc-hm-one-sentinel">
              <div className="sc-hm-one-in-about">
                <>
                  <Row>
                    <Col md={6} xs={12} sm={12}>
                      <div className="text-pop-neometry">
                        <Fade bottom duration={1250}>
                          <p className="txt_ Title" id="myElement4">
                            Sentinel<br/>Retail Store Monitoring by Vision AI
                          </p>
                          <p className="txt_ subTitle">
                            Enhancing Business by "Marketing In the Moment" leveraging visual intelligence obtained by studying customer behaviour and interactions in the store
                          </p>
                          <div className="forPC">
                            <div className="flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-4">
                              <button
                                className="font-bold px-4 lg:px-10 font-[poppins] rounded-[60px] bg-cyan-500 text-gray-100 hocus:bg-cyan-700 focus:shadow-outline focus:outline-none transition duration-300"
                              >
                                <a href="#about-sentinel">Know More</a>
                              </button>
                              <button
                                onClick={() => this.setState({videoModal: true})}
                                className="flex place-items-center bg-[transparent]"
                              >
                                <span className="sm:mt-0 flex items-center text-gray-300 transition duration-300 hocus:text-[#742cff] focus:outline-none">
                                  <PlayIcon className="stroke-1 w-12 h-12" />
                                </span>
                                <span className="ml-2 font-[poppins] font-medium text-gray-300 self-center">
                                  Watch Video
                                </span>
                              </button>
                            </div>
                          </div>
                          <div className="forMobile">
                            <Row style={tabStyle.row}>
                              <Col sm={6} xs={6} style={tabStyle.leftCol}>
                                <button
                                  className="font-bold px-4 lg:px-10 font-[poppins] rounded-[60px] bg-cyan-500 text-gray-100 hocus:bg-cyan-700 focus:shadow-outline focus:outline-none transition duration-300"
                                >
                                  <a href="#about-sentinel">Know More</a>
                                </button>
                              </Col>
                              <Col sm={6} xs={6} style={tabStyle.rightCol}>
                                  <button
                                    onClick={() => this.setState({videoModal: true})}
                                    className="flex place-items-center bg-[transparent]"
                                  >
                                    <span className="sm:mt-0 flex items-center text-gray-300 transition duration-300 hocus:text-[#742cff] focus:outline-none">
                                      <PlayIcon className="stroke-1 w-12 h-12" />
                                    </span>
                                    <span className="ml-2 font-[poppins] font-medium text-gray-300 self-center">
                                      Watch Video
                                    </span>
                                  </button>
                              </Col>
                            </Row>
                          </div>
                        </Fade>
                      </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <>
                        <div className="video-pop-neometry forPC">
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=QH7teksT_mQ"
                            playing={true}
                            loop={true}
                            muted={true}
                            width="100%"
                            height="370px"
                          />
                        </div>
                        <div className="video-pop-neometry forMobile">
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=QH7teksT_mQ"
                            playing={true}
                            loop={true}
                            muted={true}
                            style={{marginTop:"1%"}}
                            width="100%"
                            height="200px"
                          />
                        </div>
                      </>
                    </Col>
                  </Row>
                </>
              </div>
            </div>
            <div className="content-box-sentinel" id="about-sentinel">
              <Fade bottom duration={1250}>
                <Row>
                  <Col md={4} xs={12} sm={12}>
                    {/*<p className="about-midSide-div2-sentinel">Affordable Pricing, Certified Forwarders</p>*/}
                    <p className="about-midSide-div2-sentinel"></p>
                    <p className="about-midSide-div2-title">What Sentinel does</p>
                    <p className="about-midSide-div2-para">
                      Supports increasing  sales by <span className="amiware-hightlight">"Marketing in the Moment"</span> leveraging <span className="amiware-hightlight">"Visual Intelligence"</span> on customer behaviours and responses to various products, promotions and loyalty programs based on the priority of the Retail Management.<br/><br/>
                      Reduces shrinkages by strengthening vision-based Security and Surveillance harnessing Video Analytics, Proactive Alerting, and 20x faster incident resolution.<br/><br/>
                      Monitors workforce efficiency by encouraging adherence  to rules and regulations.</p>
                  </Col>
                  <Col md={8} xs={12} sm={12}>
                    {/*<img src={dashboard} className="senti-dashboard"/>*/}
                  </Col>
                </Row>
              </Fade>
            </div>
            <div className="senti-dash" id="about-div2-info">
              <Fade bottom duration={1250}>
                <center>
                  <div style={{marginBottom: "5%", marginTop:"2%"}}>
                    <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl" style={{color:"#fff"}}>
                      Key {" "}
                      <span className="text-cyan-500 inline-block">
                      Features
                      </span>
                    </h1>
                  </div>
                  <Carousel
                    autoPlay={true}
                    infinite={true}
                    autoPlaySpeed={1500}
                    partialVisible 
                    itemClass="image-item"
                    responsive={responsive}
                  >
                    <div className='cards-about-team'>
                      <div className="card-about-team-members">
                        <div className="card-body-about-team">
                          <center><img src={senti_card_1_webp} className="senti_image_card"/></center>
                        </div>
                        <p className="team-members-text-title-senti-cards">Mis-Shipment</p>
                        <p className="team-text-subtitle-cards-senti">Verification</p>
                      </div>
                    </div>
                    <div className='cards-about-team'>
                      <div className="card-about-team-members">
                        <div className="card-body-about-team">
                          <center><img src={senti_card_3_webp} className="senti_image_card"/></center>
                        </div>
                        <p className="team-members-text-title-senti-cards">Proactive Shop</p>
                        <p className="team-text-subtitle-cards-senti">Floor Monitoring</p>
                      </div>
                    </div>
                    <div className='cards-about-team'>
                      <div className="card-about-team-members">
                        <div className="card-body-about-team">
                          <center><img src={senti_card_5_webp} className="senti_image_card"/></center>
                        </div>
                        <p className="team-members-text-title-senti-cards">Seamless</p>
                        <p className="team-text-subtitle-cards-senti">Integration</p>
                      </div>
                    </div>
                    <div className='cards-about-team'>
                      <div className="card-about-team-members">
                        <div className="card-body-about-team">
                          <center><img src={senti_card_6_webp} className="senti_image_card"/></center>
                        </div>
                        <p className="team-members-text-title-senti-cards">Real-Time</p>
                        <p className="team-text-subtitle-cards-senti">Alerts</p>
                      </div>
                    </div>
                    <div className='cards-about-team'>
                      <div className="card-about-team-members">
                        <div className="card-body-about-team">
                          <center><img src={senti_card_2_webp} className="senti_image_card"/></center>
                        </div>
                        <p className="team-members-text-title-senti-cards">Custom</p>
                        <p className="team-text-subtitle-cards-senti">Video Search</p>
                      </div>
                    </div>
                    <div className='cards-about-team'>
                      <div className="card-about-team-members">
                        <div className="card-body-about-team">
                          <center><img src={senti_card_7_webp} className="senti_image_card"/></center>
                        </div>
                        <p className="team-members-text-title-senti-cards">Intelligent</p>
                        <p className="team-text-subtitle-cards-senti">Analytics</p>
                      </div>
                    </div>
                    <div className='cards-about-team'>
                      <div className="card-about-team-members">
                        <div className="card-body-about-team">
                          <center><img src={senti_card_8_webp} className="senti_image_card"/></center>
                        </div>
                        <p className="team-members-text-title-senti-cards">Summarisation</p>
                        <p className="team-text-subtitle-cards-senti">(Days to Mins)</p>
                      </div>
                    </div>
                  </Carousel>
                </center>
              </Fade>
            </div>
          </div>
          <div className="sc-hm-one-products-senti2">
            <Fade bottom duration={1250}>
              <center>
                <div style={{marginBottom: "1%"}}>
                  <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl max-w-3xl">
                    How Sentinel Helps {" "}
                    <span className="text-cyan-500">
                    Your Business
                    </span>
                  </h1>
                </div>
              </center>
              <p className="txt_products-senti-3-subtitle">Sentinel provides shop-floor monitoring and Ambient Analytics using time and metadata indexed events of violations occurring at a People, Product or Process level.</p>
              <center>
              <Row>
                <Col md={3} xs={12} sm={12}>
                  <div className="container-senti">
                    <div className="card-senti">
                        <div className="slide slide1">
                            <div className="content-senti">
                                <div className="icon">
                                  <center>
                                    <img src={search} style={{height: 70, width: 70, marginTop:"15%"}}/>
                                    <p className="senti-card-txt">Search</p>
                                  </center>
                                  
                                </div>
                            </div>
                        </div>
                        <div class="slide slide2">
                            <div class="content-senti">
                                <p>Natural Language Query</p>
                                <p>Image & Area Search</p>
                                <p>Deep Sub-Attribute Search</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <div className="container-senti2">
                    <div className="card-senti2">
                        <div className="slide slide1">
                            <div className="content-senti2">
                                <div className="icon">
                                  <center>
                                    <img src={summary} style={{height: 70, width: 70, marginTop:"15%"}}/>
                                    <p className="senti-card-txt">Summary</p>
                                  </center>
                                </div>
                            </div>
                        </div>
                        <div class="slide slide2">
                            <div class="content-senti2">
                              <p>Trailer of Key Events</p>
                              <p>Anomaly Detection</p>
                              <p>Smart Video Storage</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <div className="container-senti3">
                    <div className="card-senti3">
                        <div className="slide slide1">
                            <div className="content-senti3">
                                <div className="icon">
                                  <center>
                                    <img src={security} style={{height: 70, width: 70, marginTop:"15%"}}/>
                                    <p className="senti-card-txt">Security</p>
                                  </center>
                                </div>
                            </div>
                        </div>
                        <div class="slide slide2">
                            <div class="content-senti3">
                              <p>Unauthorized Entry Alerts</p>
                              <p>Asset Protection</p>
                              <p>Real-Time Email Notification</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <div className="container-senti4">
                    <div className="card-senti4">
                        <div className="slide slide1">
                            <div className="content-senti4">
                                <div className="icon">
                                  <center>
                                    <img src={statistics} style={{height: 70, width: 70, marginTop:"15%"}}/>
                                    <p className="senti-card-txt">Statistics</p>
                                  </center>
                                </div>
                            </div>
                        </div>
                        <div class="slide slide2">
                            <div class="content-senti4">
                              <p>Peak Traffic Analysis</p>
                              <p>Activity Heat-maps</p>
                              <p>Flow Path-maps</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </Col>
              </Row>
              </center>
            </Fade>
          </div>
          </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(sentinel_screen);
