import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import "./Navbar.css";


export const Nav = styled.nav`
	background: #111921;
	height: 80px;
	@media screen and (max-width: 768px) {
		height: 60px;
	}
	padding-left: 3%;
	padding-right: 5%;
	padding-top: 10px;
	z-Index: 999;
	width: 100vw;
	position: fixed;
`;

export const NavLink = styled(Link)`
	color: #000000;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	&.active {
		color: #fff;
	}
`;
export const NavMenu = styled.div`
	align-items: center;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;
