import React from "react";
import { withRouter } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Pulse from 'react-reveal/Pulse';
import { IoClose } from "react-icons/io5";
import info from "../../assets/info.png";
import engineering from "../../assets/engineering.png";
import wf from "../../assets/wf.png";
import Layer1 from "../../assets/Layer1.svg";
import Layer2 from "../../assets/webimage/layer2.webp";
import Layer3 from "../../assets/layer3.png";
import amiware_logo from "../../assets/amiware_logo.png";
import Carousel from 'react-multi-carousel';
import "./amiware.css";
import 'react-multi-carousel/lib/styles.css';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 75
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 100
  }
};

class amiware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amiwareModal: true,
      amiwareMain: true,
      introduction: false,
      technology: false,
      workflow: false,
    };
  }
  navigateBack = () => {
    this.props.history.goBack();
    this.toggleButton()
  }
  render() {
    return (
      <div className="amiware-bg-div-mobile">
        <Modal
          show={this.state.amiwareModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="border-radius-1"
          centered
          size='xl'
        >
          <ModalBody>
            {
              this.state.amiwareMain?
              <>
                <IoClose
                  size={30}
                  className="closeIconX"
                  onClick={this.navigateBack}
                />
                <center>
                  <div className="amiware-modal-inner-div">
                    <img
                        src={amiware_logo}
                        className="amiware_logo-image"
                    />
                    <p className="ami-layer1-txt">AmIware improves <span className="amiware-hightlight">Process Visibilty and Automation Management</span> for supply chain ecosystems with intelligent Ambient Machines, leading to minimal people-induced losses and product-linked costs.</p>
                    <br></br>
                    <Pulse top duration={500}>
                      <div className="amiware-options-mobile">
                        <Carousel
                          autoPlay={false}
                          autoPlaySpeed={1600}
                          partialVisible 
                          itemClass="image-item"
                          responsive={responsive}
                          swipeable={true}
                          draggable={true}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                        >
                            <div class="box-amiware-main-ami" onClick={() => this.setState({ introduction: true, technology: false, workflow: false, amiwareMain: false })}>
                              <img src={info} style={{height: 45, width: 45, marginBottom:"10%"}}/>
                              <p className="box-amiware-main-txt">Introduction to AmIware</p>
                            </div>
                            <div class="box-amiware-main-ami" onClick={() => this.setState({ introduction: false, technology: false, workflow: true, amiwareMain: false })}>
                              <img src={engineering} style={{height: 55, width: 55, marginBottom:"10%"}}/>
                              <p className="box-amiware-main-txt">Work<br></br>Flow</p>
                            </div>
                            <div class="box-amiware-main-ami" onClick={() => this.setState({ introduction: false, technology: true, workflow: false, amiwareMain: false })}>
                              <img src={wf} style={{height: 55, width: 55, marginBottom:"10%"}}/>
                              <p className="box-amiware-main-txt">Technology<br></br>Behind</p>
                            </div>
                        </Carousel>
                      </div>
                    </Pulse>
                  </div>
                </center>
              </>
              :
              <></>
            }
            {
              this.state.workflow?
                <>
                  <BiArrowBack
                    size={30}
                    className="closeIconBack"
                    onClick={() => this.setState({ amiwareMain: true,  introduction: false, technology: false, workflow: false,})}
                  />
                  <div className="intro-amiware-view">
                      <center>
                        <img
                            src={amiware_logo}
                            className="amiware_logo-image-small"
                        />
                      </center>
                      <img
                          src={Layer1}
                          className="ami-layer1-img"
                      />
                      <p className="layer1-txt-inner">AmIware improves <span className="amiware-hightlight">Process Visibilty and Automation Management</span> for supply chain ecosystems with intelligent Ambient Machines, leading to minimal people-induced losses and product-linked costs.</p>
                  </div>
                </>
              :
                <></>
            }
            {
              this.state.technology?
                <>
                  <BiArrowBack
                    size={30}
                    className="closeIconBack"
                    onClick={() => this.setState({ amiwareMain: true,  introduction: false, technology: false, workflow: false,})}
                  />
                  <div className="intro-amiware-view2">
                        <img
                            src={amiware_logo}
                            className="amiware_logo-image-small"
                        />
                      <img
                          src={Layer2}
                          className="ami-layer1-img"
                      />
                  </div> 
                </>
              :
                <></>
            }
            {
              this.state.introduction?
              <>
                <BiArrowBack
                  size={30}
                  className="closeIconBack"
                  onClick={() => this.setState({ amiwareMain: true,  introduction: false, technology: false, workflow: false,})}
                />
                <div className="intro-amiware-view">
                    <center>
                      <img
                          src={amiware_logo}
                          className="amiware_logo-image-small"
                      />
                    </center>
                    <p className="layer1-txt-inner">Our <span className="amiware-hightlight">context and task aware engine - AmiWare</span>, Connets with the existing IoT Infrasture & transform them into intelligent Ambient Machines each performing three fundamental operations - Sense, Process and Interact.</p>
                    <p className="layer1-txt-inner">AmiWare ensures end-to-end monitoring and reporting of processess & sub-processes within the warehouse floor by enabling collaborative HCI between <span className="amiware-hightlight">Ambient Machines</span> and<span className="amiware-hightlight"> Ambient Agents</span> (or workers)</p>
                    <img
                        src={Layer3}
                        className="layer3-img"
                    />
                </div>
              </>
              :
              <></>
            }
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(amiware);
