import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/neophyte_logo.png";
import sidebar_icon from "../../assets/icon/sidebar_icon.png";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import SideBar from "../Sidebar/sidebar";
import { FiChevronDown } from "react-icons/fi";

const styles = {
  row: {
      marginLeft: 0,
      marginRight: 0
  },
  col: {
      paddingLeft: 0,
      paddingRight: 0
  }
};


class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownView: false,
    };

  }

  render() {
    return (
      <>
        <Nav>
          <div className="side-bar">
            {this.state.sideBar ? (
              <Row style={styles.row} className="SideBarPosition">
                <Col onClick={() => this.setState({ sideBar: false })} style={styles.col}>
                  <SideBar sideBarShow={this.state.sideBar}/>
                </Col>
                <Col onClick={() => this.setState({ sideBar: false })} style={styles.col}>
                  <div className="sidebar_Shadow">
                    &nbsp;
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <div className="mobile-side-img-div2">
                    <img
                      alt="sidebar"
                      src={sidebar_icon}
                      className="sidebar-icon"
                      onClick={() => this.setState({ sideBar: true })}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mobile-side-img-div">
                    <Link to="/">
                      <center>
                        <img
                          alt="logo"
                          src={logo}
                          className="navbar_mobile_logo"
                        />
                      </center>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}
          </div>
          <NavMenu>
            <Row>
              <Col md={2}>
                <div>
                  <Link to="/">
                    <img
                      alt="logo"
                      src={logo}
                      className="logo"
                    />
                  </Link>
                </div>
              </Col>
              <Col md={10}>
                <div id="new-alignment-navbar">
                  <NavLink
                    to="/"
                    style={{ marginRight: "2.5%",  marginTop: 5}}
                    className="nav-change"
                    onMouseOver={() => this.setState({dropdownView: false})}
                  >
                    Home
                  </NavLink>
                  <p
                    className="navbar_txt_reserve"
                    onClick={this.openModal}
                    onMouseOver={() => this.setState({dropdownView: true})}
                    style={{
                      marginBottom: "1%",
                      marginRight: "2%",
                      marginTop: 2
                    }}
                  >
                    Products
                    <FiChevronDown
                      size={20}
                      className="dropdown_icon"
                      onClick={() => this.setState({ loginModal: false })}
                    />
                  </p>
                  {
                    this.state.dropdownView ?
                      <div className="categories-hover-div" onMouseOver={() => this.setState({dropdownView: true})} onMouseLeave={() => this.setState({dropdownView: false})}>
                      <center>
                        <NavLink
                          to="/disha"
                          className="nav-change-drop"
                          onMouseOut={() => this.setState({dropdownView: true})}
                        >
                          Disha
                        </NavLink>
                        <div style={{marginTop: 15, marginBottom: 15}}></div>
                        <NavLink
                          to="/neometry"
                          className="nav-change-drop"
                          onMouseOut={() => this.setState({dropdownView: true})}
                        >
                          Neometry
                        </NavLink>
                        <div style={{marginTop: 15, marginBottom: 15}}></div>
                        <NavLink
                          to="/sentinel"
                          className="nav-change-drop"
                          onMouseOut={() => this.setState({dropdownView: true})}
                        >
                          Sentinel
                        </NavLink>
                      </center>
                      </div>
                    :
                    <></>
                  }
                  <div onMouseOver={() => this.setState({dropdownView: false})}>
                    <Link to="/contact">
                      <button className="exclusiveBtn" >Contact</button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </NavMenu>
        </Nav>
      </>
    );
  }
}

export default Navbar;