import React from "react";
import { withRouter } from "react-router-dom";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/neophyte_logo.png";
import linkedin from "../../assets/linkedin.png";
import youtube from "../../assets/youtube.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Footer extends React.Component {
  render() {
    return (
        <div className="footer_contant">
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="items-cls">
                        <div className="quick-links">
                            <img
                                alt="logo"
                                src={logo}
                                className="footer-logo"
                            />
                            <p className="footer_title">Ambient Intelligence for Retail and <br className="forPC"/>Supply Chain</p>
                            <img
                                alt="logo"
                                src={youtube}
                                className="social-icons"
                                onClick={()=> window.open("https://www.youtube.com/@neophytemedia", "_blank")} 
                            />
                            <img
                                onClick={()=> window.open("https://in.linkedin.com/company/neophyteai", "_blank")} 
                                alt="logo"
                                src={linkedin}
                                className="social-icons"
                            />
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                    <div className="items-cls sec">
                        <div className="links">
                            <p className="footer_col_header">Other Links</p>
                            <p className="links">
                                <Link className="links_text" to="/disha">Disha</Link>
                            </p>
                            <p className="links">
                                <Link className="links_text" to="/sentinel">Sentinel</Link>
                            </p>
                            <p className="links">
                                <Link className="links_text" to="/neometry">NeoMetry</Link>
                            </p>
                            <p className="links">
                                <Link className="links_text" to="/contact">Contact Us</Link>
                            </p>
                            {/* <p className="links">
                                <Link className="links_text" to="/terms-of-use">Terms and Conditions</Link>
                            </p> */}
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={3} lg={3}>
                   <div className="items-cls">
                        <p className="footer_col_header">Address</p>
                        <p className="footer_info">
                            Plot No 4, Shop No 5,<br/>
                            Vishwakarma Towers,<br/>
                            Sector 21, Kharghar,<br/>
                            Navi Mumbai, Maharashtra 410210<br/><br/>
                            Email: admin@neophyte.ai<br/>
                            <a href={"tel:" + "+919087263216"}>
                                Phone: +91-700-869-6707
                            </a>
                        </p>
                   </div>
                </Col>
            </Row>
        </div>
    );
  }
}

export default withRouter(Footer);
