import React from "react";
import { withRouter } from "react-router-dom";
import "./about.css";
import Footer from "../../components/Footer/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from 'react-reveal/Fade';
import { BsArrowRightCircle } from "react-icons/bs";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

//Images
import office_1 from "../../assets/office_1.jpg";
import office_2 from "../../assets/office_2.jpg";
import office_3 from "../../assets/office_3.png";
import office_4 from "../../assets/office_4.png";
import Layer2_webp from "../../assets/webimage/layer2.webp";

import user_icon from "../../assets/team/user_icon.png";
import anurag from "../../assets/team/anurag.png";
import hongkong from "../../assets/team/hongkong.png";
import abhinav from "../../assets/team/abhinav.png"; 
import universita from "../../assets/team/universita.png";
import pranjal from "../../assets/team/pranjal.JPG"; 
import madras from "../../assets/team/madras.webp";
import ashish from "../../assets/team/ashish.jpg";
import gourav from "../../assets/team/gourav.jpg"; 
import suman from "../../assets/team/suman.jpg"; 
import sunny from "../../assets/team/sunny.jpg"; 
import dp_dogra from "../../assets/team/dp_dogra.jpg";
import mayur from "../../assets/team/mayur.jpg";
import rajkishor from "../../assets/team/rajkishor.jpg";
import dr_shailesh from "../../assets/team/dr_shailesh.jpg";
import suki from "../../assets/team/suki.jpg";



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};

class about extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: false,
      leaders: true,
      switchView: true
    };
    this.changeView = this.changeView.bind(this);
  }
  changeView() {
    this.setState({ switchView: !this.state.switchView });
  }
  componentDidMount() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
      <div className="container-box">
        <div>
            <div>
              <div className="sc-hm-one-about">
                <div className="sc-hm-one-in-about">
                  <Container>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={8} xs={12} sm={12}>
                        <div className="text-pop-about">
                          <Fade bottom duration={1250}>
                            <p className="txt_ Title" id="myElement4">
                              Driven by force of excellence
                            </p>
                            <p className="txt_ subTitle">
                            Neophyte is dedicated to realizing the vision of industry 4.0 in terms of the next-generation sustainable supply chain ecosystem, ensuring that the best product is delivered to the customer at the most affordable price and in the shortest amount of time possible, and providing complete operational visibility and ambient (shop floor) analytics to ensure a total experience (TX) for all stakeholders.
                            </p>
                            <div className="align-items">
                                <button
                                  className="visit-site-about"
                                >
                                  <a href="#about-neophyte">Meet Team</a>
                                </button>
                            </div>
                          </Fade>
                        </div>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <div className="sc-hm-one-products">
                <Fade bottom duration={1250}>
                  <p className="txt_products vision-txt-margin font-bold text-5xl">Our Vision</p>
                  <Row>
                    <Col md={5} xs={12} sm={12}>
                      <p className="vision-para margin-VisionPara">Neophyte for the first time delivers ambient integrated intelligence to support and enable companies and stakeholders to utilize ambient services in a timely and sustainable manner.
Our technology is on a constant strive to make the mechanization ubiquitous, and customized to the stakeholders in such a way that it can improve operational efficiency, reduce costs, and create digital experience.
</p>
                      <p className="vision-para">Neophyte contributes to ESG principles with the least amount of time, money, and effort possible. Additionally, we are actively attempting to reduce digital pollution and carbon footprint. We create flexible infrastructure and intelligent assistance for interactions between people and their surroundings in the warehouse ecosystem.</p>
                      {/* <ul className="vision-bullets">
                        <li>Lesser effort, time and cost</li>
                        <li>Reduced carbon footprint and digital pollution</li>
                        <li>Improved quality, productivity, and total experience</li>
                      </ul> */}
                    </Col>
                    <Col md={7} xs={12} sm={12}>
                      <img
                          src={Layer2_webp}
                          className="ami-layer1-img-forAbout"
                      />
                    </Col>
                  </Row>
                </Fade>
              </div>
            </div>
            {
              this.state.switchView ?
                <div className="why-us-about" id="about-neophyte">
                  <div className="why-us-about-inner">
                    <Fade bottom duration={1250}>
                      <p className="why-midSide-div2-title-about font-bold text-5xl">The Leadership</p>
                      <Row>
                        <Col md={4}>
                          <div className='cards-about-team'>
                            <div className="card-about-team">
                              <div className="card-body-about-team">
                                <center><img src={anurag} className="team-imgs"/></center>
                              </div>
                              <p className="team-text-title">Anurag S.</p>
                              <p className="team-text-subtitle">Co-Founder & CEO</p>
                              <center><img src={hongkong} className="team-univ-imgs"/></center>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='cards-about-team'>
                            <div className="card-about-team">
                              <div className="card-body-about-team">
                                <center><img src={abhinav} className="team-imgs"/></center>
                              </div>
                              <p className="team-text-title">Dr. Abhinav Anand</p>
                              <p className="team-text-subtitle">Co-Founder & CTO</p>
                              <center><img src={universita} className="team-univ-imgs-universita"/></center>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='cards-about-team'>
                            <div className="card-about-team">
                              <div className="card-body-about-team">
                                <center><img src={pranjal} className="team-imgs"/></center>
                              </div>
                              <p className="team-text-title">Pranjal Bhaskare</p>
                              <p className="team-text-subtitle">VP Engineering</p>
                              <center><img src={madras} className="team-univ-imgs-madras"/></center>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <center>
                          <button
                            className="visit-site-about-team"
                            onClick={this.changeView}
                          >
                            <Row>
                              <Col md={10} sm={10} xs={10}>
                                <p style={{marginTop: 2}}>
                                  Meet The Team Behind
                                </p>
                              </Col>
                              <Col md={2} sm={2} xs={2}>
                                <BsArrowRightCircle
                                  size={22}
                                  className="nextIconX"
                                />
                              </Col>
                            </Row>
                          </button>
                        </center>
                      </div>
                    </Fade>
                  </div>
                </div>
              :
              <div className="why-us-about">
                <div className="why-us-about-inner">
                  <Fade bottom duration={1250}>
                    <p className="why-midSide-div2-title-about">The Team Behind</p>
                    <Carousel
                      autoPlay={true}
                      autoPlaySpeed={1700}
                      infinite={true}
                      ssr
                      partialVisible 
                      itemClass="image-item"
                      responsive={responsive}
                    >
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={gourav} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Gourav Atre</p>
                          <p className="team-text-subtitle-members">Data Scientist</p>
                        </div>
                      </div>
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={mayur} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Mayur Pawar</p>
                          <p className="team-text-subtitle-members">Frontend Developer</p>
                        </div>
                      </div>
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={rajkishor} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Rajkishore Pradhan</p>
                          <p className="team-text-subtitle-members">Frontend Developer</p>
                        </div>
                      </div>
                      
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={sunny} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Dr. Sunny Verma</p>
                          <p className="team-text-subtitle-members"> Research Scientist (Mentor)</p>
                        </div>
                      </div>
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={dp_dogra} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Dr. D. P. Dogra</p>
                          <p className="team-text-subtitle-members">Computer Vision & AR/VR (Mentor)</p>
                        </div>
                      </div>
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={dr_shailesh} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Dr. Shailesh Kumar</p>
                          <p className="team-text-subtitle-members">AI and Data Science (Mentor)</p>
                        </div>
                      </div>
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={user_icon} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Dr. Prashant Deshpande</p>
                          <p className="team-text-subtitle-members">Emerging Technologies (Mentor)</p>
                        </div>
                      </div>
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={suki} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Suki Kwok</p>
                          <p className="team-text-subtitle-members">UI/UX Designer</p>
                        </div>
                      </div>
                      <div className='cards-about-team'>
                        <div className="card-about-team-members">
                          <div className="card-body-about-team">
                            <center><img src={ashish} className="team-imgs"/></center>
                          </div>
                          <p className="team-members-text-title">Ashish Sasmal</p>
                          <p className="team-text-subtitle-members">Account & Audits</p>
                        </div>
                      </div>
                    </Carousel>
                    <div>
                      <center>
                        <button
                          className="visit-site-about-team"
                          onClick={this.changeView}
                        >
                          <Row>
                            <Col md={10} sm={10} xs={10}>
                              <p style={{marginTop: 2}}>
                                Meet The Leaders
                              </p>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                              <BsArrowRightCircle
                                size={22}
                                className="nextIconX"
                              />
                            </Col>
                          </Row>
                        </button>
                      </center>
                    </div>
                  </Fade>
                </div>
              </div>
            }
            <div className="sc-hm-one-products">
              <Fade bottom duration={1250}>
                <p className="txt_products font-bold text-5xl">Our Workspace</p> 
                <p className="product-subTitle font-medium">The place where all the great and creative minds of Neophyte collaborate.</p>
                <div className="carousel-div">
                  <Row>
                    <Col md={3} xs={6} sm={6}>
                      <div className="page-container">
                        <img src={office_1} className="office-imgs img-down"/>
                      </div>
                    </Col>
                    <Col md={3} xs={6} sm={6}>
                      <div className="page-container">
                        <img src={office_2} className="office-imgs"/>
                      </div>
                    </Col>
                    <Col md={3} xs={6} sm={6}>
                      <div className="page-container">
                        <img src={office_3} className="office-imgs img-down"/>
                      </div>
                    </Col>
                    <Col md={3} xs={6} sm={6}>
                      <div className="page-container">
                          <img src={office_4} className="office-imgs"/>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Fade>
            </div>
            
          </div>
        <Footer />
      </div>
    );
  }
}
class BlogCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { flipped: false };
    this.flip = this.flip.bind(this);
  }

  flip = () => {
    this.setState({ flipped: !this.state.flipped });
  }
  render() {
    return (
      <div onMouseEnter={this.flip} onMouseLeave={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
        <Front cardImage={this.props.cardImage} cardText={this.props.cardText}/>
        <Back />
      </div>

    )
  }
}

class Front extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="front">
        <ImageArea cardImage={this.props.cardImage}/>
        <MainArea cardText={this.props.cardText}/>
      </div>
    )
  }
}

class Back extends React.Component {
  render() {
    return (
      <div className="back">
        <p>Profiles people and prevents people-induced loss, non compliance and indiscipline by enhancing existing security and surveillance ecosystem</p>
        <p>Bloggity bloggity bloggity blog. This would be the full text of the abbreviated blog post.</p>
      </div>
    )
  }
}

class ImageArea extends React.Component {
  render() {
    return (
      <div className="image-container">
        <img className="card-image" src={this.props.cardImage}></img>
      </div>
    )
  }

}

class MainArea extends React.Component {
  render() {
    return (
      <div className="main-area">
        <div className="blog-post">
          <p className="title">{this.props.cardText}</p>
        </div>
      </div>
    )
  }
}
export default withRouter(about);
