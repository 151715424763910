import React from "react";
import { withRouter } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
// import { BiInfoCircle } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { BiCctv } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
// import { FaWarehouse } from "react-icons/fa";

import logo from "../../assets/neophyte_logo.png";
import "./custom.scss";
import "./sidebar_styles.css";
import "react-multi-carousel/lib/styles.css";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: this.props.sideBarShow,
    };
  }
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  navigateHome = () => {
    this.props.history.push("/");
    this.toggleButton();
  };
  navigateAbout = () => {
    this.props.history.push("/about");
    this.toggleButton();
  };
  navigateAbout = () => {
    this.props.history.push("/disha");
    this.toggleButton();
  };
  navigateSentinel = () => {
    this.props.history.push("/sentinel");
    this.toggleButton();
  };
  navigateNeoMetry = () => {
    this.props.history.push("/neometry");
    this.toggleButton();
  };
  navigateBlogs = () => {
    this.props.history.push("/blogs");
    this.toggleButton();
  };
  navigateAmiware = () => {
    this.props.history.push("/amiware");
    this.toggleButton();
  };
  navigateContact = () => {
    this.props.history.push("/contact");
    this.toggleButton();
  };
  render() {
    return (
      <>
        {this.state.sideBar ? (
          <ProSidebar className="sidebarHeight">
            <center>
              <img
                src={logo}
                className="sidebarLogo"
              />
            </center>
            <Menu iconShape="square" className="sidebar-icons-margin">
              {/* <MenuItem
                icon={
                  <BiInfoCircle
                    size={32}
                    color={"#fff"}
                    style={{ marginRight: 10 }}
                  />
                }
              >
                <span onClick={this.navigateAbout} className="sidebar-item-txt">
                  About
                </span>
              </MenuItem> */}
              <MenuItem
                icon={
                  <AiOutlineHome
                    size={32}
                    color={"#fff"}
                    style={{ marginRight: 10 }}
                  />
                }
              >
                <span onClick={this.navigateHome} className="sidebar-item-txt">
                  Home
                </span>
              </MenuItem>
              <MenuItem
                icon={
                  <MdOutlineDashboard
                    size={32}
                    color={"#fff"}
                    style={{ marginRight: 10 }}
                  />
                }
              >
                <span onClick={this.navigateAbout} className="sidebar-item-txt">
                  Disha
                </span>
              </MenuItem>
              <MenuItem
                icon={
                  <BiCctv size={26} color="white" style={{ marginRight: 10 }} />
                }
              >
                <span
                  onClick={this.navigateSentinel}
                  className="sidebar-item-txt"
                >
                  Sentinel
                </span>
              </MenuItem>
              <MenuItem
                icon={
                  <BsBoxSeam
                    size={26}
                    color={"#fff"}
                    style={{ marginRight: 10 }}
                  />
                }
              >
                <span
                  onClick={this.navigateNeoMetry}
                  className="sidebar-item-txt"
                >
                  Neometry
                </span>
              </MenuItem>
              {/* <MenuItem icon={<BsNewspaper size={25} color={"#fff"} style={{marginRight: 10}}/>}>
                  <span onClick={this.navigateBlogs} className="sidebar-item-txt">Blogs</span>
                </MenuItem>
                <MenuItem icon={<BiSupport size={27} color={"#fff"} style={{marginRight: 10}}/>}>
                  <span onClick={this.navigateContact} className="sidebar-item-txt">Contact</span>
                </MenuItem>
                <MenuItem icon={<GiButterfly size={27} color={"#fff"} style={{marginRight: 10}}/>}>
                  <span onClick={this.navigateAmiware} className="sidebar-item-txt">AmIWare</span>
                </MenuItem> */}
            </Menu>
          </ProSidebar>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(SideBar);
