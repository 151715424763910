import React from "react";
import { withRouter, Link } from "react-router-dom";
import "./neometry.css";
import Footer from "../../components/Footer/footer";
import Fade from 'react-reveal/Fade';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BiCheck } from "react-icons/bi";
import Carousel from 'react-multi-carousel';
import MetaTags from 'react-meta-tags';
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { Helmet } from "react-helmet-async";


//Files
import neometry_screen_1 from "../../assets/neometry.png";
import neometry_screen_2 from "../../assets/image_3.png";
import neometry_card_1 from "../../assets/neometry_card_1.png";
import neometry_card_2 from "../../assets/neometry_card_2.png";
import neometry_card_3 from "../../assets/neometry_card_3.png";
import neometry_card_4 from "../../assets/neometry_card_4.png";
import neometry_card_5 from "../../assets/neometry_card_5.png";
import neometry_card_6 from "../../assets/neometry_card_6.png";
import neometry_card_7 from "../../assets/neometry_card_7.png";
import neometry_card_8 from "../../assets/webimage/senti_card_5.webp";
import neometry_screen_first from "../../assets/neometry_screen_1.jpg";
import neometry_1 from "../../assets/neometry_1.png";
import neometry_2 from "../../assets/neometry_4.png";
import neometry_3 from "../../assets/neometry_3.png";
import no_touch from "../../assets/no-touch.png";
import ai from "../../assets/ai.png";
import cartoons from "../../assets/cartoons.png";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};
const responsive_2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};
const responsive_3 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};

const tabStyle = {
  row: {
      marginLeft: 0,
      marginRight: 0
  },
  leftCol: {
      paddingLeft: 0,
      paddingRight: 5
  },
  rightCol: {
    paddingLeft: 5,
    paddingRight: 0
  }
};

class neometry_screen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchView: true,
      sentinel: true,
      neometry: false,
      videoModal: false
    };
  }
  changeView = () => {
    this.setState({ sentinelView: !this.state.sentinelView, sentinel: !this.state.sentinel, neometry: !this.state.neometry });
  };
  componentDidMount() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
      <div className="container-box">
        <Helmet>
          <title>Neophyte - NeoMetry</title>
          <meta name="description" content="Accurate and comprehensive profiling of inbound shipments on moving conveyors for automated Cataloging and Sorting of orders based on outbound distribution criteria."></meta>
          <meta name="keywords" content="NeoMetryRevolution, AIWarehouseOperations, StreamlinePackageHandling, ProductivityBoost, RealTimeMeasurements, EfficientInventoryManagement, SustainableWarehousing, OptimizeOperations, CuttingEdgeTechnology, GreenWarehouseSolutions"></meta>
          <link rel="canonical" href="https://neophyte.ai/neometry"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
        </Helmet>
        <Modal
          show={this.state.videoModal}
          onHide={() => this.setState({videoModal: false})}
          keyboard={false}
          centered
          size="xl"
        >
          <ModalBody>
            <div className="forPC">
              <iframe
                style={{
                  width: "100%",
                  height: "80vh",
                }}
                src="https://www.youtube.com/embed/9d-N5iUkDJI?si=smZImB57S64a7-kR"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="forMobile">
              <iframe
                style={{
                  width: "100%",
                  height: "75vh",
                }}
                src="https://www.youtube.com/embed/9d-N5iUkDJI?si=smZImB57S64a7-kR"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </ModalBody>
        </Modal>
        <div>
          <div>
            <div className="sc-hm-one-sentinel">
              <div className="sc-hm-one-in-about">
                <>
                  <Row>
                    <Col md={6} xs={12} sm={12}>
                      <div className="text-pop-neometry">
                        <Fade bottom duration={1250}>
                          <p className="txt_ Title" id="myElement4">
                            NeoMetry<br/>A Smart Product Profiler
                          </p>
                          <p className="txt_ subTitle">
                            Accurate and comprehensive profiling of inbound shipments on moving conveyors for automated Cataloging and Sorting of orders based on outbound distribution criteria.
                          </p>
                          <div className="forPC">
                            <div className="flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-4">
                              <button
                                className="font-bold px-4 lg:px-10 font-[poppins] rounded-[60px] bg-cyan-500 text-gray-100 hocus:bg-cyan-700 focus:shadow-outline focus:outline-none transition duration-300"
                              >
                                <a href="#about-neometry">Know More</a>
                              </button>
                              <button
                                onClick={() => this.setState({videoModal: true})}
                                className="flex place-items-center bg-[transparent]"
                              >
                                <span className="sm:mt-0 flex items-center text-gray-300 transition duration-300 hocus:text-[#742cff] focus:outline-none">
                                  <PlayIcon className="stroke-1 w-12 h-12" />
                                </span>
                                <span className="ml-2 font-[poppins] font-medium text-gray-300 self-center">
                                  Watch Video
                                </span>
                              </button>
                            </div>
                          </div>
                          <div className="forMobile">
                            <Row style={tabStyle.row}>
                              <Col sm={6} xs={6} style={tabStyle.leftCol}>
                                <button
                                  className="mt-3 font-bold px-4 lg:px-10 font-[poppins] rounded-[60px] bg-cyan-500 text-gray-100 hocus:bg-cyan-700 focus:shadow-outline focus:outline-none transition duration-300"
                                >
                                  <a href="#about-neometry">Know More</a>
                                </button>
                              </Col>
                              <Col sm={6} xs={6} style={tabStyle.rightCol}>
                                  <button
                                    onClick={() => this.setState({videoModal: true})}
                                    className="flex place-items-center bg-[transparent]"
                                  >
                                    <span className="sm:mt-0 flex items-center text-gray-300 transition duration-300 hocus:text-[#742cff] focus:outline-none">
                                      <PlayIcon className="stroke-1 w-12 h-12" />
                                    </span>
                                    <span className="ml-2 font-[poppins] font-medium text-gray-300 self-center">
                                      Watch Video
                                    </span>
                                  </button>
                              </Col>
                            </Row>
                          </div>
                        </Fade>
                      </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <>
                        <div className="video-pop-neometry forPC">
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=9d-N5iUkDJI"
                            playing={true}
                            loop={true}
                            muted={true}
                            width="100%"
                            height="370px"
                          />
                        </div>
                        <div className="video-pop-neometry forMobile">
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=9d-N5iUkDJI"
                            playing={true}
                            loop={true}
                            muted={true}
                            style={{marginTop:"1%"}}
                            width="100%"
                            height="200px"
                          />
                        </div>
                      </>
                    </Col>
                  </Row>
                </>
              </div>
            </div>
            <div className="content-box div2" id="about-neometry">
              <Row>
                <Col md={7} sm={12} xs={12}>
                  <Fade bottom duration={1400}>
                    {/*<p className="about-midSide-div2-home">Affordable Pricing, Certified Forwarders</p>*/}
                    <p className="about-neometry-div2-title">NeoMetry enhances effectiveness and reduces costs</p>
                    <p className="about-midSide-div2-para">NeoMetry profiles the products accurately and comprehensively at the vendors’ packing stations or receiving stations/warehouses of  E-commerce companies by measuring LBHW and recording information scanning Bar / QR code / OCR using Vision Al and Ambient Intelligence.</p>
                    <p className="about-midSide-div2-para">Traces and Tracks the product in its journey from the vendor packing station/warehouse to the customer's doorstep till unboxed, authenticating the product at each point of validation with visual intelligence bridging the reality gap.</p>
                    <p className="about-midSide-div2-para">Helps optimise warehouses and trucks by using visual intelligence and ambient intelligence platforms.</p>
                    <p className="about-midSide-div2-para">Ensures zero defect authentic product delivery leading to customer satisfaction and reducing return-related costs and wastages.</p>
                    <p className="about-midSide-div2-para">Contributes to carbon reduction by optimising Packing Materials, use of warehouses, trucks, and fleet management while substantially reducing returns.</p>
                  </Fade>
                </Col>
                <Col md={5} sm={12} xs={12}>
                  <Carousel
                    autoPlay={false}
                    infinite={true}
                    autoPlaySpeed={2000}
                    showDots={true}
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                    responsive={responsive_2}
                  >
                    <img src={neometry_screen_2} className="stock_img-final-card2"/>
                    <img src={neometry_screen_1} className="stock_img-final-card"/>
                  </Carousel>
                </Col>
              </Row>
            </div>
          </div>
          <div className="why-us-neo">
            <Row>
              <Col md={4} sm={12} xs={12} className="forPC">
                <div className="neometry_div_1">
                  <center>
                    <div class="box-neometry-1">
                      <div className="box-neometry-1-inner">
                        <p className="box-text-title">Help Your Business Win</p>
                        <p className="box-text-subtitle">Deploying Ambient Intelligence for Sustainable Al and Low-touch or No-touch technology to increase the effectiveness of the Supply Chain.</p>
                        <Link to="/contact">
                          <button
                            className="requestSolution"
                          >
                            Connect with us
                          </button>
                        </Link>
                      </div>
                    </div>
                  </center>
                </div>
              </Col>
              <Col md={8} sm={12} xs={12}>
                <div className="neomet-second-right-cards">
                  <Carousel
                    autoPlay={false}
                    autoPlaySpeed={1600}
                    ssr
                    partialVisible 
                    itemClass="image-item"
                    responsive={responsive}
                  >
                    <div className="neometry_div_1">
                      <center>
                        <div class="box-neometry-2">
                          <div className="box-neometry-2-inner">
                            <img src={no_touch} style={{height: 55, width: 55}}/>
                            <p className="box-text-title-2">Low-Touch / No-Touch</p>
                            <ul className="neometry-bullets">
                              <li><p className="box-text-subtitle-2">Touchless and continuous product profiling.</p></li>
                              <li><p className="box-text-subtitle-2">Measure once & manage throughout philosophy.</p></li>
                            </ul>
                          </div>
                        </div>
                      </center>
                    </div>
                    <div className="neometry_div_1">
                      <center>
                        <div class="box-neometry-2">
                          <div className="box-neometry-2-inner">
                            <img src={ai} style={{height: 55, width: 55}}/>
                            <p className="box-text-title-2">Sustainable AI & ML Vision</p>
                            <ul className="neometry-bullets">
                              <li><p className="box-text-subtitle-2">Dream learning from synthetic simulation and digital twins.</p></li>
                              <li><p className="box-text-subtitle-2">Active learning pipeline to manage KPIs trade-offs.</p></li>
                            </ul>
                          </div>
                        </div>
                      </center>
                    </div>
                    <div className="neometry_div_1">
                      <center>
                        <div class="box-neometry-2">
                          <div className="box-neometry-2-inner">
                          <img src={cartoons} style={{height: 55, width: 55}}/>
                          <p className="box-text-title-2">Adaptive and Personalized</p>
                          <ul className="neometry-bullets">
                            <li><p className="box-text-subtitle-2">Dynamic and static product profiler</p></li>
                            <li><p className="box-text-subtitle-2">Volumetric profiling for products of all sizes.</p></li>
                          </ul>
                          </div>
                        </div>
                      </center>
                    </div>
                    {/* <div className="neometry_div_1">
                      <center>
                        <div class="box-neometry-2">
                          <div className="box-neometry-2-inner">
                          <img src={plug} style={{height: 55, width: 55}}/>
                          <p className="box-text-title-2">Seamless integration</p>
                          <ul className="neometry-bullets">
                            <li><p className="box-text-subtitle-2">Place, Plug, and Ready-to-use.</p></li>
                            <li><p className="box-text-subtitle-2">Auto-Environment Calibration.</p></li>
                            <li><p className="box-text-subtitle-2">Integration with Existing WMS.</p></li>
                          </ul>
                          </div>
                        </div>
                      </center>
                    </div> */}
                  </Carousel>
                  {/* <div className="explore-next-div">
                    <span className="explore-more-txt">Explore more </span>
                    <BsArrowRightCircle size={22} style={{float:"right", color:"#fff", marginTop: 3, marginLeft: 10}}/>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
          <div className="sc-hm-one-products-neo">
            <Fade bottom duration={1400}>
              <center>
                <div style={{marginBottom: "1.8%"}}>
                  <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl">
                    What {" "}
                    <span className="text-cyan-500 inline-block">
                    We Offer
                    </span>
                  </h1>
                </div>
              </center>
              <p className="product-subTitle">NeoMetry offers precise and comprehensive profiling of objects, showcasing unmatched accuracy and essential features.</p>
              <div className="carousel-div-neo">
                <Row>
                  <Col md={4} sm={12} xs={12}>
                    <div class='container-neo'>
                      <div class='card-neo'>
                        <div class='card__top-neo'>
                          <i class='fas-neo fa-book-neo'></i>
                          <img src={neometry_1} style={{width:85, height: 85}}/>
                          <p className="card-box-neo-text">Deep Product<br/>Profiling</p>
                        </div>
                        <div class='card__bottom-neo'>
                          <p className="senti-whatweoffer-card-txt">Volume (LBH) and Weight</p>
                          <p className="senti-whatweoffer-card-txt">Visual Damage Assessment</p>
                          <p className="senti-whatweoffer-card-txt">Barcode & QR code Detection</p>
                          <p className="senti-whatweoffer-card-txt">Text, Logo and Tags Detection</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} sm={12} xs={12}>
                    <div class='container-neo'>
                      <div class='card-neo'>
                        <div class='card__top-neo'>
                          <i class='fas-neo fa-book-neo'></i>
                          <img src={neometry_2} style={{width:90, height: 90}}/>
                          <p className="card-box-neo-text">Optimal Sorting<br/>and Routing</p>
                        </div>
                        <div class='card__bottom-neo'>
                          <p className="senti-whatweoffer-card-txt">SKU Classification and Counting</p>
                          <p className="senti-whatweoffer-card-txt">Volume (or weight) based sorting</p>
                          <p className="senti-whatweoffer-card-txt">Text, PIN, Barcode based sorting</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} sm={12} xs={12}>
                    <div class='container-neo'>
                      <div class='card-neo'>
                        <div class='card__top-neo'>
                          <i class='fas-neo fa-book-neo'></i>
                          <img src={neometry_3} style={{width:90, height: 90}}/>
                          <p className="card-box-neo-text">Cataloguing and<br/>Quality Assesment</p>
                        </div>
                        <div class='card__bottom-neo'>
                          <p className="senti-whatweoffer-card-txt">Product-linked (visual) tags extraction</p>
                          <p className="senti-whatweoffer-card-txt">Content Moderation & Product Tagging</p>
                          <p className="senti-whatweoffer-card-txt">Vision-based Automated QA/QC process</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
          <div className="senti-dash" id="about-div2-info">
            <Fade bottom duration={1250}>
              <center>
                <div style={{marginBottom: "5%"}}>
                  <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl" style={{color:"#fff"}}>
                    Key {" "}
                    <span className="text-cyan-500 inline-block">
                    Features
                    </span>
                  </h1>
                </div>
                <Carousel
                  autoPlay={true}
                  infinite={true}
                  autoPlaySpeed={1500}
                  partialVisible 
                  itemClass="image-item"
                  responsive={responsive_3}
                >
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_1} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Volumetric</p>
                      <p className="team-text-subtitle-cards-senti">Profiling</p>
                    </div>
                  </div>
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_2} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Quality</p>
                      <p className="team-text-subtitle-cards-senti">Verification</p>
                    </div>
                  </div>
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_3} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Damage</p>
                      <p className="team-text-subtitle-cards-senti">Assessment</p>
                    </div>
                  </div>
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_4} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Automated</p>
                      <p className="team-text-subtitle-cards-senti">GNR</p>
                    </div>
                  </div>
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_5} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Product (SKU)</p>
                      <p className="team-text-subtitle-cards-senti">Classification</p>
                    </div>
                  </div>
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_6} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Automated</p>
                      <p className="team-text-subtitle-cards-senti">Sorting</p>
                    </div>
                  </div>
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_7} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Automated</p>
                      <p className="team-text-subtitle-cards-senti">Cataloguing</p>
                    </div>
                  </div>
                  <div className='cards-about-team'>
                    <div className="card-about-team-members">
                      <div className="card-body-about-team">
                        <center><img src={neometry_card_8} className="senti_image_card"/></center>
                      </div>
                      <p className="team-members-text-title-senti-cards">Seamless</p>
                      <p className="team-text-subtitle-cards-senti">Integration</p>
                    </div>
                  </div>
                </Carousel>
              </center>
            </Fade>
          </div>
          <div className="sc-hm-one-products-neometry">
            <Row>
              <Col md={6} xs={12} sm={12}>
                <Fade bottom duration={1400}>
                  <p className="about-midSide-div2-title">Competitive Advantages and Use Cases</p>
                  <p className="about-midSide-div2-para">We pride ourselves on providing one of the best-in-class warehouse solutions that range from optimising packing material requirements, fleet management decision-making, and early detection of damages as well as providing seamless connectivity with WMS and WCS.</p>
                  <Row>
                    <Col>
                      <Row>
                        <div>
                          <BiCheck
                            size={30}
                            className="closeIcon"
                          />
                          <span className="pricing-span">Fleet Management</span>
                        </div>
                      </Row>
                      <Row>
                        <div>
                          <BiCheck
                            size={30}
                            className="closeIcon"
                          />
                          <span className="pricing-span">Package Optimisation</span>
                        </div>
                      </Row>
                      <Row>
                        <div>
                          <BiCheck
                            size={30}
                            className="closeIcon"
                          />
                          <span className="pricing-span">Real-Time High-Value Cage Area Monitoring</span>
                        </div>
                      </Row>
                      <Row>
                        <div>
                          <BiCheck
                            size={30}
                            className="closeIcon"
                          />
                          <span className="pricing-span">Damage & Quality Assessment</span>
                        </div>
                      </Row>
                      {/*<Link to="/about">
                        <button
                          className="requestQuote"
                          style={{marginTop: "4%"}}
                        >
                          More About Us
                        </button>
                      </Link>*/}
                    </Col>
                  </Row>
                </Fade>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <img src={neometry_screen_first} className="warehouse-img-2-neo"/>
              </Col>
            </Row>
          </div>
        </div> 
        <Footer />
      </div>
    );
  }
}
class BlogCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { flipped: false };
    this.flip = this.flip.bind(this);
  }

  flip = () => {
    this.setState({ flipped: !this.state.flipped });
  }
  render() {
    return (
      <div onMouseEnter={this.flip} onMouseLeave={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
        <Front cardImage={this.props.cardImage} cardText={this.props.cardText}/>
        <Back />
      </div>

    )
  }
}

class Front extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="front">
        <ImageArea cardImage={this.props.cardImage}/>
        <MainArea cardText={this.props.cardText}/>
      </div>
    )
  }
}

class Back extends React.Component {
  render() {
    return (
      <div className="back">
        <p>Profiles people and prevents people-induced loss, non compliance and indiscipline by enhancing existing security and surveillance ecosystem</p>
        <p>Bloggity bloggity bloggity blog. This would be the full text of the abbreviated blog post.</p>
      </div>
    )
  }
}

class ImageArea extends React.Component {
  render() {
    return (
      <div className="image-container">
        <img className="card-image" src={this.props.cardImage}></img>
      </div>
    )
  }

}

class MainArea extends React.Component {
  render() {
    return (
      <div className="main-area">
        <div className="blog-post">
          <p className="title">{this.props.cardText}</p>
        </div>
      </div>
    )
  }
}
export default withRouter(neometry_screen);
