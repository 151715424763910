import React from "react";
import axios from 'axios';
import { withRouter, Link } from "react-router-dom";
import "./home.css";
import "./home.scss"
import Footer from "../../components/Footer/footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import { ToastContainer, toast } from "react-toastify";
import 'react-multi-carousel/lib/styles.css';
import "react-toastify/dist/ReactToastify.css";


//Images
import warehouse from "../../assets/warehouse.png";
import distribution from "../../assets/distribution.png";
import merchandizing from "../../assets/merchandizing.png";
import sourcing from "../../assets/sourcing.png";
import team from "../../assets/team.png";
import product from "../../assets/product.png";
import settings from "../../assets/settings.png";
import amiware_logo from "../../assets/amiware_logo.png";
import sentinel from "../../assets/sentinel.png";
import neometry from "../../assets/neometry.png";
import new_landing_intro from "../../assets/new_landing_intro.png";
import new_landing_intro_2 from "../../assets/new_landing_intro_2.png";
import disha from "../../assets/disha.png";
import Layer3_webp from "../../assets/webimage/layer3.webp";
import reliance from "../../assets/reliance.png";
import croma from "../../assets/croma.png";
import flipkart from "../../assets/flipkart.png";
import home_amiware from "../../assets/home_amiware.png";
import { Helmet } from "react-helmet-async";

const tabStyle = {
  row: {
      marginLeft: 0,
      marginRight: 0
  },
  col: {
      paddingLeft: 0,
      paddingRight: 0
  },
  col2: {
    paddingLeft: 3,
    paddingRight: 3,
}
};

class home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      sentinel: true,
      neometry: false,
      center: {
        lat: 59.95,
        lng: 30.33
      },
      fullName: '',
      email: '',
      message: '',
      arrows: ['left_arrow', 'center_arrow', 'right_arrow'],
      currentIndex: 0,
      currentImageIndex: 0,
      errors: {},
      layoutView: "Issues",
      isBlurActive: false
    }
  }
  handleFullName = async (event) => {
    let errors = this.state.errors;
    let fullName = event.target.value.length
    if (fullName>1) {
      errors["fullName"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["fullName"] = "Enter a valid name";
      this.setState({ errors: errors});
    }
    this.setState({
        fullName: event.target.value,
    });
  }
  handleEmail = async (event) => {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Enter a valid email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value.toLowerCase(),
    });
  }
  handleMessage = async (event) => {
    let errors = this.state.errors;
    let message = event.target.value.length
    if (message>10) {
      errors["message"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["message"] = "Must be at least 10 characters";
      this.setState({ errors: errors});
    }
    this.setState({ message: event.target.value });
  }
  checkSubmit = () => {
    const {fullName, email, message} = this.state;
    let nameWarning = this.state.errors["fullName"];
    let emailWarning = this.state.errors["email"];
    let messageWarning = this.state.errors["message"];
    if(fullName !== "" && email !== "" && message !== ""){
      if(emailWarning == null && nameWarning == null && messageWarning == null)
      {
        toast.info("Sending ..", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.submitData()
      }
      
    }
    else{
      toast.warn("Form Incomplete", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
      });
    }
  }
  submitData = () => {
    const { fullName, email, message } = this.state;
    axios.post('http://localhost:8006/message', { fullName, email, message })
      .then((response) => {
        console.log("helllo");
        if (response.data.status === 201) {
              toast.success("Form submitted", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              this.resetForm()
            } else if (response.data.status === 'fail') {
              toast.error("Error encountered", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
      });
  }
  resetForm(){
    this.setState({fullName: "", email: "", message: ""})
  }
  componentDidMount() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.interval = setInterval(this.toggleArrows, 4500); 
    this.interval = setInterval(this.toggleImages, 3500);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  changeView = () => {
    this.setState({ sentinelView: !this.state.sentinelView, sentinel: !this.state.sentinel, neometry: !this.state.neometry });
  }
  toggleArrows = () => {
    const { arrows, currentIndex } = this.state;
    const nextIndex = (currentIndex + 1) % arrows.length;

    this.setState({
      currentIndex: nextIndex,
    });
  };
  toggleImages = () => {
    const { arrows, currentImageIndex } = this.state;
    const nextIndex = (currentImageIndex + 1) % arrows.length;

    this.setState({
      currentImageIndex: nextIndex,
      //currentImageIndex: 0
    });
  };
  render() {
    const { currentIndex, currentImageIndex } = this.state;
    const { fullName, email, message, layoutView, isBlurActive } = this.state;
    return (  
      <div className="container-box">
        <Helmet>
          <title>Neophyte</title>
          <link rel="canonical" href="https://neophyte.ai/"/>
          <meta name="keywords" content="Retail, E-commerce, Consumer Brands, Supply Chain, Neophyte, Disha, Neometry, Sentinel, Vision AI, Ambient Intelligence, Warehouse, Amlware, Visual Merchandising, Warehouse Management System, Transport Management System, Point of Sale Systems, Enterprise Resource Planning System, Ambient Machines"></meta>
          <meta name="description" content="Bridging the reality gap between information layer & physical layer by leveraging Vision AI & Ambient Intelligence"/>
        </Helmet>
        <div>
          <div>
            <div className="sc-hm-one">
              <div className="sc-hm-one-in">
                <>
                  <Row>
                    <Col md={7} xs={12} sm={12}>
                      <div className="text-pop">
                        <Fade bottom>
                          <p className="txt_ Title" id="myElement4">
                            Bridging the reality gap between information layer & physical layer by leveraging Vision AI & Ambient Intelligence
                          </p>
                          <p className="txt_ subTitle">
                            Neophyte takes an outside-in-view and builds low-cost use-case-led Vision AI solutions for everyday problems of Retail, E-commerce, Consumer Brands, and Supply Chain industries resulting in higher revenue and lower cost.
                          </p>
                          <p className="trusted_by_txt">Trusted by</p>
                          <img className="trusted_by_icons" src={reliance}></img>
                          <img className="trusted_by_icons" style={{marginTop: 5}} src={flipkart}></img>
                          {/* <img className="trusted_by_icons_2" style={{marginTop: 4}} src={croma}></img>*/}
                          <br clear="all"/>
                          <div className="align-items">
                            <Link to="/contact">
                              <button
                                className="visit-site"
                              >
                                Get Demo
                              </button>
                            </Link>
                          </div>
                        </Fade>
                      </div>
                    </Col>
                    {/* <Col md={6} lg={6} xs={12} sm={12}>
                      <div className="iframe-forMobile">
                          <iframe width="95%" height="240" src="https://www.youtube.com/embed/IMPbKVb8y8s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{marginBottom:"10%", borderRadius: 20}}></iframe>
                      </div>
                      <div className="iframe-forPC">
                        <center>
                          <iframe width="100%" height="415" src="https://www.youtube.com/embed/IMPbKVb8y8s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{marginTop:"15%", marginBottom:"8%", borderRadius: 25}}></iframe>
                        </center>
                      </div>
                    </Col> */}
                    <Col md={5} lg={5} className="forPC">
                      {
                        currentImageIndex == 0 ?
                          <Fade>
                            <img className="sc-hm-one-image" src={new_landing_intro}></img>
                          </Fade>
                        :
                          <></>
                      }
                      {
                        currentImageIndex == 1 ?
                          <Fade>
                            <img className="sc-hm-three-image" src={new_landing_intro_2}></img>
                          </Fade>
                        :
                          <></>
                      }
                      {
                        currentImageIndex == 2 ?
                          <Fade>
                            <img className="sc-hm-three-image" src={neometry}></img>
                          </Fade>
                        :
                          <></>
                      }
                    </Col>
                  </Row>
                </>
              </div>
            </div>
            <div className="content-box div2">
              <br className="forMobile"/>
              <Fade bottom duration={1850}>
                <center>
                  <div style={{marginBottom: "2%"}}>
                    <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl">
                      Our {" "}
                      <span className="text-cyan-500 inline-block" >
                        Solutions
                      </span>
                    </h1>
                  </div>
                </center>
                <Row>
                  <Col md={4} xs={12} sm={12}>
                    <div className='cards'>
                      <div className="card">
                        <div className="card-body">
                          <img src={team}/>
                          <h3 className="text-white">Sentinel</h3>
                          <p>Increasing sales by <span className="our_solution_cards_highlight">"Marketing in the Moment"</span> by studying customer behavior in real-time in the retail store by applying <span className="our_solution_cards_highlight">Vision AI</span> and <span className="our_solution_cards_highlight">Ambient Intelligence.</span></p>
                          <p>Enhancing worker efficiency and productivity by monitoring visually the behaviour and alerting instantaneously to prevent compliance violations and deviations from defined processes and KPIs.</p>
                        </div>
                      </div>
                    </div> 
                  </Col>
                  <Col md={4} xs={12} sm={12}>
                    <div className='cards'>
                      <div className="card">
                        <div className="card-body">
                        <img src={product}/>
                          <h3 className="text-white">Disha</h3>
                          <p>Attracting Customers by improving <span className="our_solution_cards_highlight">Shelf-fullness & Visual Merchandising</span> with the help of <span className="our_solution_cards_highlight">Vision AI</span>.</p>
                          <p>Improving <span className="our_solution_cards_highlight">Shelf-fullness, Replenishment,</span> and <span className="our_solution_cards_highlight">Planogram compliance</span> by integrating with <span className="our_solution_cards_highlight">OMS.</span></p>
                        </div>
                      </div>
                    </div> 
                  </Col>
                  <Col md={4} xs={12} sm={12}>
                    <div className='cards'>
                      <div className="card">
                        <div className="card-body">
                        <img src={settings}/>
                          <h3 className="text-white">NeoMetry</h3>
                          <p>Profiling products for E-commerce companies using Vision Al and Ambient Intelligence. Tracing & tracking the products while optimising warehouse and transportation to the customer's doorstep minimising returns by ensuring authenticity and defect detections.</p>
                        </div>
                      </div>
                    </div> 
                  </Col>
                </Row>
                <div className="stock_img_margins">
                  <center>
                    <p className="about-midSide-div2-title">Sustainable Business Solutions</p>
                    <p className="about-midSide-div2-para">Harnessing existing IP cameras and IoT infrastructure in retail stores and warehouses, Neophyte Ambient Intelligence Platform <span className="amiware-hightlight">(AmIware)</span> creates an Integrated ecosystem powered by Vision AI and Ambient Intelligence for sustainable and effective decision-making.</p>
                    <p className="about-midSide-div2-para">It provides full <span className="amiware-hightlight">product and process visibility</span> for E-commerce, Retail, and Consumer Brand Business leaders. Improves shelf-fullness & <span className="amiware-hightlight">Visual Merchandising</span> as per Plannograming resulting in higher sales. Ensures the authenticity of the products in the E-commerce industry by tracing and tracking visually at every node from the vendor's warehouse to the doorstep of the customer, <span className="amiware-hightlight">reducing returns</span>. Supports Consumer Brands with <span className="amiware-hightlight">visual intelligence</span> of their products displayed in the store.</p>
                  </center>
                </div>
                <div className="forPC">
                  <div className="layer_margins"onMouseOver={() => this.setState({isBlurActive: false, layoutView: "Issues"})}>
                    <Row className="layer_alignments" style={tabStyle.row}>
                      <Col md={2} style={tabStyle.col}>
                        <div className="layer_boxes_inner_is">
                          <p className="layers_title">Information<br/>Systems</p>
                        </div>
                      </Col>
                      <Col md={10} style={tabStyle.col}>
                        <Row>
                          <Col md={3} style={tabStyle.col}>
                            <div className="layer_boxes_inner_odd">
                              <p className="layer_boxes_inner_txt">Enterprise Resource<br/>Planning System</p>
                            </div>
                          </Col>
                          <Col md={3} style={tabStyle.col}>
                            <div className="layer_boxes_inner_even">
                              <p className="layer_boxes_inner_txt">Warehouse<br/>Management System</p>
                            </div>
                          </Col>
                          <Col md={3} style={tabStyle.col}>
                            <div className="layer_boxes_inner_odd">
                              <p className="layer_boxes_inner_txt">Transport<br/>Management System</p>
                            </div>
                          </Col>
                          <Col md={3} style={tabStyle.col}>
                            <div className="layer_boxes_inner_even">
                              <p className="layer_boxes_inner_txt">Point of Sale<br/>Systems</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="layer_issues" onMouseEnter={() => this.setState({isBlurActive: true})} onClick={() => this.state.isBlurActive ? this.setState({layoutView: "AmIWare"}) : this.setState({sentinelView: true})}>
                    {
                      layoutView == "Issues" ?
                      <>
                        {
                          isBlurActive ?
                            <Pulse>
                              <p className="blur_txt" onClick={()=>this.setState({layoutView: "AmIWare"})}>Tap to fix these retail chain problems</p>
                            </Pulse>
                          :
                          <></>
                        }
                        <Row className={isBlurActive ? "layer_alignments blur_view" : "layer_alignments"} style={tabStyle.row}>
                          <Col md={12} style={tabStyle.col2}>
                            <Row className="layer_issues_boxes">
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner left_top_radius" style={{backgroundColor: "#ca724c"}}>
                                  <p className="layer_boxes_inner_txt">Empty<br/>Shelves</p>
                                </div>
                              </Col>
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner" style={{backgroundColor: "#b76849"}}>
                                  <p className="layer_boxes_inner_txt">Poor<br/>Assortment</p>
                                </div>
                              </Col>
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner" style={{backgroundColor: "#cd754d"}}>
                                  <p className="layer_boxes_inner_txt">Sub-optimal<br/>Planogram</p>
                                </div>
                              </Col>
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner right_top_radius" style={{backgroundColor: "#5e3e31"}}>
                                  <p className="layer_boxes_inner_txt">Inventory<br/>Accuracy</p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className={isBlurActive ? "layer_alignments blur_view" : "layer_alignments"} style={tabStyle.row}>
                          <Col md={12} style={tabStyle.col2}>
                            <Row className="layer_issues_boxes">
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner left_bottom_radius" style={{backgroundColor: "#935641"}}>
                                  <p className="layer_boxes_inner_txt">Mis-shipments</p>
                                </div>
                              </Col>
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner" style={{backgroundColor: "#674136"}}>
                                  <p className="layer_boxes_inner_txt">Defective<br/>Products</p>
                                </div>
                              </Col>
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner" style={{backgroundColor: "#48342d"}}>
                                  <p className="layer_boxes_inner_txt">Empty<br/>Miles Travelled</p>
                                </div>
                              </Col>
                              <Col style={tabStyle.col2}>
                                <div className="layer_issues_boxes_inner right_bottom_radius" style={{backgroundColor: "#be6b49"}}>
                                  <p className="layer_boxes_inner_txt">Volumetric<br/>Disputes</p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                      :
                      <div className="layer_issues_hover_heights">
                        <center>
                          <Pulse>
                            <div className="amiware_logo_div">
                                <img
                                    src={amiware_logo}
                                    className="amiware_logo-image-small_home"
                                />
                                <span className="amiware-title">An Intelligence Middleware for Retail, E-Com & Supply Chain</span>
                            </div>
                            <Row className="layer_alignments">
                              <Col md={12}>
                                <Row className="layer_issues_boxes">
                                  <Col>
                                    <div className="layer_issues_boxes_inner_fixes">
                                      <p className="layer_boxes_inner_txt">Ground Visibility</p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="layer_issues_boxes_inner_fixes">
                                      <p className="layer_boxes_inner_txt">Monitor Compliance</p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="layer_issues_boxes_inner_fixes">
                                      <p className="layer_boxes_inner_txt">Decision Support</p>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Pulse>
                        </center>
                      </div>
                    }
                  </div>
                  <div onMouseOver={() => this.setState({isBlurActive: false, layoutView: "Issues"})}>
                    <Row className="layer_alignments" style={tabStyle.row}>
                      <Col md={2} style={tabStyle.col}>
                        <div className="layer_boxes_inner_ps">
                          <p className="layers_title">Physical<br/>Systems</p>
                        </div>
                      </Col>
                      <Col md={10} style={tabStyle.col}>
                        <Row className="layer_boxes">
                          <Col md={3} style={tabStyle.col}>
                            <center>
                              <div className="layer_boxes_inner_circular">
                                <img
                                  src={sourcing}
                                  className="layer_boxes_inner_img"
                                />
                              </div>
                              <p className="layer_boxes_inner_txt">Sourcing</p>
                            </center>
                          </Col>
                          <Col md={3} style={tabStyle.col}>
                            <center>
                              <div className="layer_boxes_inner_circular">
                                <img
                                  src={warehouse}
                                  className="layer_boxes_inner_img"
                                />
                              </div>
                              <p className="layer_boxes_inner_txt">Warehousing</p>
                            </center>
                          </Col>
                          <Col md={3} style={tabStyle.col}>
                            <center>
                              <div className="layer_boxes_inner_circular">
                                <img
                                  src={distribution}
                                  className="layer_boxes_inner_img"
                                />
                              </div>
                              <p className="layer_boxes_inner_txt">Distribution</p>
                            </center>
                          </Col>
                          <Col md={3} style={tabStyle.col}>
                            <center>
                              <div className="layer_boxes_inner_circular">
                                <img
                                  src={merchandizing}
                                  className="layer_boxes_inner_img"
                                />
                              </div>
                              <p className="layer_boxes_inner_txt">Merchandizing</p>
                            </center>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="forMobile">
                  <img src={home_amiware} className="home_amiware"/>
                </div>
              </Fade>
            </div>
          </div>
          <div className="sc-hm-one-products">
            <Fade bottom duration={1100}>
              <center>
                <div style={{marginBottom: "1%"}}>
                  <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl">
                    Our {" "}
                    <span className="text-cyan-500 inline-block">
                    Products & Platform
                    </span>
                  </h1>
                  <p className="product-subTitle product-subTitle-home">At NeoPhyte, we are committed to giving products whose outcomes can be used based on your priorities and personalized for the stakeholders.</p>
                </div>
              </center>
              <center>
                <div className="carousel-div">
                  <Row style={tabStyle.row}>
                    <Col md={4} style={tabStyle.col}>
                      <div className="page-container">
                          <BlogCard cardImage={disha} cardText={"Disha"} backText={"Disha (Digital Shelf Analysis) is a store and shelf tracking solution for enhancing visual merchandising, compliance with planogram, and on-time replenishment of the shelves while ensuring 20x faster cycle- counting/auditing."}/>
                      </div>
                    </Col>
                    <Col md={4} style={tabStyle.col}>
                      <div className="page-container">
                          <BlogCard cardImage={sentinel} cardText={"Sentinel"} backText={"Sentinel profiles people and monitors premises to promote business/sales and prevent losses due to non-compliance/indiscipline."}/>
                      </div>
                    </Col>
                    <Col md={4} style={tabStyle.col}>
                      <div className="page-container">
                          <BlogCard cardImage={neometry} cardText={"NeoMetry"} backText={"NeoMetry is a Smart Product Profiler that ensures Error-Free Inbound, Volume Optimization, and 7x Processing Velocity."}/>
                      </div>
                    </Col>
                  </Row>
                </div>
              </center>
            </Fade>
            <Row style={{marginLeft:"10%", marginRight:"10%"}} className="forPC">
              <Col md={4}>
                {
                  currentIndex == 0 ?
                    <div className="graph__wrapper forPC">
                      <svg width="100%" height="107px" viewBox="0 0 315 107" version="1.1" style={{ overflow: 'visible' }}>
                        <defs>
                          <marker id="arrow" markerWidth="7" markerHeight="7" refX="0" refY="3.5" orient="auto" markerUnits="strokeWidth">
                            <path d="M0,0 L0,7 L7,3.5 z" fill="#06b6d4" />
                          </marker>
                        </defs>
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeMiterlimit="10">
                          <path className="path" fill="none" stroke="#06b6d4" strokeWidth="5" strokeLinejoin="round" markerEnd="url(#arrow)" d="M1.4,2.1c0,0,86,57,211.5,41.5s172.5-24.5,289,81"/>
                          <path className="dashed" fill="none" stroke="white" strokeWidth="8" strokeLinejoin="round" d="M1.4,2.1c0,0,86,57,211.5,41.5s172.5-24.5,289,81"/>
                        </g>
                      </svg>
                    </div>
                    :
                    <></>
                }
              </Col>
              <Col md={4}>
                {
                  currentIndex == 1 ?
                    <div className="graph__wrapper rotateDiv forPC">
                      <svg width="100%" height="107px" viewBox="0 0 315 107" version="1.1" style={{ overflow: 'visible' }}>
                        <defs>
                          <marker id="arrow" markerWidth="7" markerHeight="7" refX="0" refY="3.5" orient="auto" markerUnits="strokeWidth">
                            <path d="M0,0 L0,7 L7,3.5 z" fill="#06b6d4" />
                          </marker>
                        </defs>
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeMiterlimit="10">
                          <path className="path" fill="none" stroke="#06b6d4" strokeWidth="5" strokeLinejoin="round" markerEnd="url(#arrow)" d="M100,53.5 Q160,30 220,53.5" />
                          <path className="dashed" fill="none" stroke="white" strokeWidth="8" strokeLinejoin="round" d="M100,53.5 Q160,30 220,53.5" />
                        </g>
                      </svg>
                    </div>
                    :
                    <></>
                }
              </Col>
              <Col md={4}>
                {
                  currentIndex == 2 ?
                    <div className="graph__wrapper forPC">
                      <svg width="100%" height="107px" viewBox="0 0 315 107" version="1.1" style={{ overflow: 'visible' }}>
                        <defs>
                          <marker id="arrow" markerWidth="7" markerHeight="7" refX="0" refY="3.5" orient="auto" markerUnits="strokeWidth">
                            <path d="M0,0 L0,7 L7,3.5 z" fill="#06b6d4" />
                          </marker>
                        </defs>
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeMiterlimit="10">
                          <path className="path" fill="none" stroke="#06b6d4" strokeWidth="5" strokeLinejoin="round" markerEnd="url(#arrow)" d="M314.6,2.1c0,0,-86,57,-211.5,41.5s-172.5-24.5,-289,81"/>
                          <path className="dashed" fill="none" stroke="white" strokeWidth="8" strokeLinejoin="round" d="M314.6,2.1c0,0,-86,57,-211.5,41.5s-172.5-24.5,-289,81"/>
                        </g>
                      </svg>
                    </div>
                
                    :
                    <></>
                }
              </Col>
            </Row>
          </div>
          <div className="sc-hm-one-products-home2">
            <Fade bottom duration={1100}>
            <center>
                <div style={{marginTop: "2%"}}>
                  <h1 className="font-[poppins] font-semibold text-3xl md:text-5xl leading-snug max-w-3xl">
                    Underlying {" "}
                    <span className="text-cyan-500 inline-block">
                    Magic
                    </span>
                  </h1>
                </div>
              </center>
              <div>
                <center>
                  <div className="amiware_logo_div">
                      <img
                          src={amiware_logo}
                          className="amiware_logo-image-small_home"
                      />
                      <span className="amiware-title">An Intelligence Middleware for Retail, E-com & Supply Chain</span>
                  </div>
                </center>
                <p className="layer1-txt-inner">Our <span className="amiware-hightlight">context and task-aware engine - AmIWare</span>, connects with existing CCTV cameras and IoT infrastructure and transforms them into intelligent Ambient Machines each performing three fundamental operations - <span className="amiware-hightlight">Sense, Process</span>, and <span className="amiware-hightlight">Interact</span></p>
                <p className="layer1-txt-inner">AmIWare ensures end-to-end monitoring and reporting of processess & sub-processes within the store / warehouse floor by enabling collaborative HCI between <span className="amiware-hightlight">Ambient Machines</span> and<span className="amiware-hightlight"> Ambient Agents</span> (or workers)</p>
                
                  <img
                      src={Layer3_webp}
                      className="layer3-img"
                  />
              </div>
              </Fade>
          </div>
          <div className="why-us-contact" id="contact-us">
            <Row>
              <Col md={5} xs={12} sm={12}>
                <div className="contact-div-first-home">
                  <Fade bottom duration={1400}>
                    <p className="contact-div1-title">Contact Information</p>
                    <p className="contact-div1-subtitle">Fill up the form and our team will get back to you</p>
                    <div className="contact-icon-first-home">
                      <Row>
                        <Col md={1} xs={2} sm={2}>
                          <FaPhoneAlt
                            size={22}
                            className="loc-icons"
                          />
                        </Col>
                        <Col md={5} xs={10} sm={10}>
                          <a href={"tel:" + "+918826627310"}>
                            <p className="contact-div1-subtitle-home number">+91 882-662-7310</p>
                          </a>
                        </Col>
                      </Row>
                      <Row style={{marginTop: "3%"}}>
                        <Col md={1} xs={2} sm={2}>
                          <FaPhoneAlt
                            size={22}
                            className="loc-icons"
                          />
                        </Col>
                        <Col md={5} xs={10} sm={10}>
                          <a href={"tel:" + "+917008696707"}>
                            <p className="contact-div1-subtitle-home number">+91 700-869-6707</p>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="contact-icon-second-home">
                      <Row>
                        <Col md={1} xs={2} sm={2}>
                          <IoIosMail
                            size={28}
                            className="loc-icons"
                          />
                        </Col>
                        <Col md={4} xs={10} sm={10}>
                          <p className="contact-div1-subtitle-home number" onClick={() => window.location = 'mailto:admin@neophyte.ai'}>admin@neophyte.ai</p>
                        </Col>
                      </Row>
                    </div>
                    <div className="contact-icon-second-home">
                      <Row>
                        <Col md={1} xs={2} sm={2}>
                          <ImLocation
                            size={28}
                            className="loc-icons"
                          />
                        </Col>
                        <Col md={9} xs={10} sm={10}>
                          <p className="contact-div1-subtitle-home number-2" onClick={() => window.open("https://maps.google.com?q="+19.05033358336438+","+73.06530312559231 )}>
                            Plot No 4, Shop No 5,<br/>
                            Vishwakarma Towers,
                            Sector 21, Kharghar
                            Navi Mumbai, Maharashtra 410210
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Fade>
                </div>
              </Col>
              <Col md={7} xs={12} sm={12}>
                <div className="sc-hm-one-contact">
                  <div className="sc-hm-one-in-contact">
                    <div className="home-contact-inner">
                      <Fade bottom duration={1400}>
                        <p className="inputHeaderTxt">Full Name</p>
                        <input
                          className="in-cus-ct input_"
                          type="text" 
                          value={fullName} 
                          onFocus={(e) => {this.handleFullName(e)}}
                          onChange={(e) => {this.handleFullName(e)}}
                          placeholder="Full Name"
                          enterKeyHint="next"
                        />
                        {
                          this.state.errors["fullName"] ?
                            <span
                              className="validateErrorTxt"
                            >
                              {this.state.errors["fullName"]}
                            </span>
                            :
                            <><br className="contactBR" /></>
                        }
                        <p className="inputHeaderTxt">Email ID</p>
                        <input
                          className="in-cus-ct input_"
                          type="text"
                          value={email} 
                          onFocus={(e) => {this.handleEmail(e)}}
                          onChange={(e) => {this.handleEmail(e)}}
                          placeholder="Email ID"
                          inputMode="email"
                          enterKeyHint="next"
                        />
                        {
                          this.state.errors["email"] ?
                            <span
                              className="validateErrorTxt"
                            >
                              {this.state.errors["email"]}
                            </span>
                            :
                            <><br className="contactBR" /></>
                        }
                        <p className="inputHeaderTxt">Message</p>
                        <textarea
                          className="in-cus-ct messageInput"
                          type="text"
                          value={message} 
                          onFocus={(e) => {this.handleMessage(e)}}
                          onChange={(e) => {this.handleMessage(e)}}
                          placeholder="Message"
                        />
                        {
                          this.state.errors["message"] ?
                            <span
                              className="validateErrorTxt"
                            >
                              {this.state.errors["message"]}
                            </span>
                            :
                            <></>
                        }
                        <center>
                          <button 
                            onClick={this.checkSubmit}
                            className="requestQuote-homecontact"
                          >
                            Submit
                          </button>
                        </center>
                      </Fade>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>    
        <Footer />
      </div>
    );
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
  }
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }
  onMessageChange(event) {
    this.setState({message: event.target.value})
  }
  handleSubmit(event) {
  }
}
class BlogCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { flipped: false };
    this.flip = this.flip.bind(this);
  }

  flip = () => {
    this.setState({ flipped: !this.state.flipped });
  }
  render() {
    return (
      <div onMouseEnter={this.flip} onMouseLeave={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
        <Front cardImage={this.props.cardImage} cardText={this.props.cardText}/>
        <Back backText={this.props.backText} cardText={this.props.cardText}/>
      </div>

    )
  }
}

class Front extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="front">
        <ImageArea cardImage={this.props.cardImage}/>
        <MainArea cardText={this.props.cardText}/>
      </div>
    )
  }
}

class Back extends React.Component {
  render() {
    return (
      <div className="back">
        <p className="prod-cards-hover-back">{this.props.backText}</p>
        <br clear="all"/>
        {
          this.props.cardText === "NeoMetry" ?
          <Link to="/neometry">
            <button
              className="cards-products-visit-site-1"
            >
              Read more
            </button>
          </Link>
          :
          <></>
        }
        {
          this.props.cardText === "Sentinel" ?
          <Link to="/sentinel">
            <button
              className="cards-products-visit-site-1"
            >
              Read more
            </button>
          </Link>
          :
          <></>
        }
        {
          this.props.cardText === "Disha" ?
          <Link to="/disha">
            <button
              className="cards-products-visit-site-1"
            >
              Read more
            </button>
          </Link>
          :
          <></>
        }
      </div>
    )
  }
}

class ImageArea extends React.Component {
  render() {
    return (
      <div className="image-container">
        <img className="card-image" src={this.props.cardImage}></img>
      </div>
    )
  }

}

class MainArea extends React.Component {
  render() {
    return (
      <div className="main-area">
        <div className="blog-post">
          <p className="title">{this.props.cardText}</p>
        </div>
      </div>
    )
  }
}
export default withRouter(home);
