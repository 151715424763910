import React from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from 'react-reveal/Fade';
import { ToastContainer, toast } from "react-toastify";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { ImLocation } from "react-icons/im";
// import MetaTags from 'react-meta-tags';

import 'react-multi-carousel/lib/styles.css';
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet-async";

class contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      message: '',
      errors: {},
    };
  }
  componentDidMount() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  handleFullName = async (event) => {
    let errors = this.state.errors;
    let fullName = event.target.value.length
    if (fullName>1) {
      errors["fullName"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["fullName"] = "Enter a valid name";
      this.setState({ errors: errors});
    }
    this.setState({
        fullName: event.target.value,
    });
  }
  handleEmail = async (event) => {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Enter a valid email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value.toLowerCase(),
    });
  }
  handleMessage = async (event) => {
    let errors = this.state.errors;
    let message = event.target.value.length
    if (message>10) {
      errors["message"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["message"] = "Must be at least 10 characters";
      this.setState({ errors: errors});
    }
    this.setState({ message: event.target.value });
  }
  checkSubmit = () => {
    const {fullName, email, message} = this.state;
    let nameWarning = this.state.errors["fullName"];
    let emailWarning = this.state.errors["email"];
    let messageWarning = this.state.errors["message"];
    if(fullName !== "" && email !== "" && message !== ""){
      if(emailWarning == null && nameWarning == null && messageWarning == null)
      {
        toast.info("Sending ..", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.submitData()
      }
      
    }
    else{
      toast.warn("Form Incomplete", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
      });
    }
  }
  submitData = () => {
    const { fullName, email, message } = this.state;
    axios.post('http://localhost:8006/message', { fullName, email, message })
      .then((response) => {
        console.log("helllo");
        if (response.data.status === 201) {
              toast.success("Form submitted", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              this.resetForm()
            } else if (response.data.status === 'fail') {
              toast.error("Error encountered", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
      });
  }
  resetForm(){
    this.setState({fullName: "", email: "", message: ""})
  }
  render() {
    const { fullName, email, message } = this.state;
    return (
      <div className="amiware-bg-div-mobile">
        <Helmet>
          <title>Neophyte - Contact Us</title>
          <meta name="description" content="Fill up the form and our team will get back to you"></meta>
          <link rel="canonical" href="https://neophyte.ai/contact"/>
          <meta name="keywords" content="Neophyte Contact, Demo, Disha Demo, Neometry Demo, Sentinel Demo"></meta>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
        </Helmet>
        <div className="why-us-contact" id="contact-us">
          <Row>
            <Col md={5} xs={12} sm={12}>
              <div className="contact-div-first-home">
                <Fade bottom duration={1400}>
                  <p className="contact-div1-title">Contact Information</p>
                  <p className="contact-div1-subtitle">Fill up the form and our team will get back to you</p>
                  <div className="contact-icon-first-home">
                    <Row>
                      <Col md={1} xs={2} sm={2}>
                        <FaPhoneAlt
                          size={22}
                          className="loc-icons"
                        />
                      </Col>
                      <Col md={5} xs={10} sm={10}>
                        <a href={"tel: +918826627310"}>
                          <p className="contact-div1-subtitle-home number">+91 882-662-7310</p>
                        </a>
                      </Col>
                    </Row>
                    <Row style={{marginTop: "3%"}}>
                      <Col md={1} xs={2} sm={2}>
                        <FaPhoneAlt
                          size={22}
                          className="loc-icons"
                        />
                      </Col>
                      <Col md={5} xs={10} sm={10}>
                        <a href={"tel: +917008696707"}>
                          <p className="contact-div1-subtitle-home number">+91 700-869-6707</p>
                        </a>
                      </Col>
                    </Row>
                  </div>
                  <div className="contact-icon-second-home">
                    <Row>
                      <Col md={1} xs={2} sm={2}>
                        <IoIosMail
                          size={28}
                          className="loc-icons"
                        />
                      </Col>
                      <Col md={4} xs={10} sm={10}>
                        <p className="contact-div1-subtitle-home number" onClick={() => window.location = 'mailto:admin@neophyte.ai'}>admin@neophyte.ai</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="contact-icon-second-home">
                    <Row>
                      <Col md={1} xs={2} sm={2}>
                        <ImLocation
                          size={28}
                          className="loc-icons"
                        />
                      </Col>
                      <Col md={9} xs={10} sm={10}>
                        <p className="contact-div1-subtitle-home number-2" onClick={() => window.open("https://maps.google.com?q="+19.05033358336438+","+73.06530312559231 )}>
                          Plot No 4, Shop No 5,<br/>
                          Vishwakarma Towers,
                          Sector 21, Kharghar
                          Navi Mumbai, Maharashtra 410210
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Fade>
              </div>
            </Col>
            <Col md={7} xs={12} sm={12}>
              <div className="sc-hm-one-contact">
                <div className="sc-hm-one-in-contact">
                  <div className="home-contact-inner">
                    <Fade bottom duration={1400}>
                      <p className="inputHeaderTxt">Full Name</p>
                      <input
                        className="in-cus-ct input_"
                        type="text" 
                        value={fullName} 
                        onFocus={(e) => {this.handleFullName(e)}}
                        onChange={(e) => {this.handleFullName(e)}}
                        placeholder="Full Name"
                        enterKeyHint="next"
                      />
                      {
                        this.state.errors["fullName"] ?
                          <span
                            className="validateErrorTxt"
                          >
                            {this.state.errors["fullName"]}
                          </span>
                          :
                          <><br className="contactBR" /></>
                      }
                      <p className="inputHeaderTxt">Email ID</p>
                      <input
                        className="in-cus-ct input_"
                        type="text"
                        value={email} 
                        onFocus={(e) => {this.handleEmail(e)}}
                        onChange={(e) => {this.handleEmail(e)}}
                        placeholder="Email ID"
                        inputMode="email"
                        enterKeyHint="next"
                      />
                      {
                        this.state.errors["email"] ?
                          <span
                            className="validateErrorTxt"
                          >
                            {this.state.errors["email"]}
                          </span>
                          :
                          <><br className="contactBR" /></>
                      }
                      <p className="inputHeaderTxt">Message</p>
                      <textarea
                        className="in-cus-ct messageInput"
                        type="text"
                        value={message} 
                        onFocus={(e) => {this.handleMessage(e)}}
                        onChange={(e) => {this.handleMessage(e)}}
                        placeholder="Message"
                      />
                      {
                        this.state.errors["message"] ?
                          <span
                            className="validateErrorTxt"
                          >
                            {this.state.errors["message"]}
                          </span>
                          :
                          <></>
                      }
                      <center>
                        <button 
                          onClick={this.checkSubmit}
                          className="requestQuote-homecontact"
                        >
                          Submit
                        </button>
                      </center>
                    </Fade>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    );
  }
}

export default withRouter(contact);
